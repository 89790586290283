import type { Skill } from "@js/types/admin";

import { SelectableSkillItem } from "./selectable-skill-item";

type SelectedPopularSkillsProps = {
  skills?: Pick<Skill, "id" | "name">[];
  handleClick: (skillId: number) => void;
};

export const SelectablePopularSkills = ({
  skills,
  handleClick,
}: SelectedPopularSkillsProps) => {
  return (
    <>
      {skills?.map((skill) => {
        return (
          <SelectableSkillItem
            key={skill.id}
            skill={skill}
            handleClick={handleClick}
          />
        );
      })}
    </>
  );
};
