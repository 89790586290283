import { useGetPopularSkillsQuery } from "../api";

export const useFetchRolePopularSkills = (roles: number[] | undefined) => {
  const hasRoles = Boolean(roles && roles.length);

  const { data: popularSkills, isFetching } = useGetPopularSkillsQuery({
    roleIds: hasRoles ? roles : undefined,
  });

  return { popularSkills, isFetching };
};
