import { useEffect, useMemo, useState } from "react";

import { clickMoveToDraftsATS } from "@js/apps/employer/actions";
import { atsIntegrationApiUtil } from "@js/apps/employer/api";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useNavigate } from "@js/hooks";
import { useDebounce } from "@js/hooks/use-debounce";
import type { ATSIntegration } from "@js/types/employer";

import {
  setAtsJobsMovedToDraftCount,
  toggleShowPostImportInfoBar,
} from "../../ats-jobs-slice";
import { useMoveATSJobsToDraft } from "../use-move-ats-jobs-to-draft";
import { usePublishSelectedJobs } from "../use-publish-selected-jobs";
import { useSelectedATSJobs } from "../use-selected-ats-jobs";

import { useATSIntegrationJobsPolling } from "./use-ats-integration-jobs-polling";

export const useATSIntegrationModal = ({
  integration,
}: {
  integration: ATSIntegration;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { deselectJobs, selectJobs, selectedJobsIds } = useSelectedATSJobs();
  const { moveATSJobsToDraft } = useMoveATSJobsToDraft();

  const { atsJobs, isLoading } = useATSIntegrationJobsPolling({
    integrationId: integration.id,
  });

  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 150);

  const filteredAtsJobs = useMemo(
    () =>
      atsJobs?.filter((item) =>
        item.title.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
      ) || [],
    [atsJobs, debouncedSearchValue],
  );

  const selectedJobs = useMemo(() => {
    return (
      atsJobs?.filter((job) => selectedJobsIds.includes(job.external_id)) ?? []
    );
  }, [selectedJobsIds, atsJobs]);

  const filteredSelectedJobs = useMemo(() => {
    return filteredAtsJobs.filter((job) =>
      selectedJobsIds.includes(job.external_id),
    );
  }, [selectedJobsIds, filteredAtsJobs]);

  const { publishSelectedATSJobs } = usePublishSelectedJobs(selectedJobs);

  const alreadyImportedJobsCount = useMemo(
    () => filteredAtsJobs.filter((job) => job.already_imported).length,
    [filteredAtsJobs],
  );

  const unsyncedJobCount = filteredAtsJobs.length - alreadyImportedJobsCount;

  const selectedJobsCount = selectedJobs.length;
  const filteredSelectedJobsCount = filteredSelectedJobs.length;
  const allJobsSelected =
    filteredSelectedJobsCount > 0 &&
    filteredAtsJobs.length - alreadyImportedJobsCount ===
      filteredSelectedJobsCount;
  const anyJobsSelected = selectedJobsCount > 0;
  const someJobsSelected = !allJobsSelected && anyJobsSelected;

  const handleMoveATSJobsToDraft = async () => {
    dispatch(clickMoveToDraftsATS());
    await moveATSJobsToDraft(selectedJobs)
      .then(() => {
        ModalInstance.close();
        dispatch(setAtsJobsMovedToDraftCount(selectedJobsCount));
        dispatch(toggleShowPostImportInfoBar());
        deselectJobs();
        dispatch(atsIntegrationApiUtil.invalidateTags(["ATSSyncedJobs"]));
        navigate("/employer/dashboard/my_jobs/");
      })
      .catch(() => {
        Snackbar.error("Something went wrong.");
      });
  };

  const selectVisibleJobs = () => {
    selectJobs(
      filteredAtsJobs
        .filter((job) => !job.already_imported)
        .map((job) => job.external_id),
    );
  };

  const handleToggleSelectJobs = () => {
    if (allJobsSelected) {
      deselectJobs();

      return;
    }

    selectVisibleJobs();
  };

  const handleSelectAllJobs = () => {
    selectVisibleJobs();
  };

  useEffect(() => {
    if (atsJobs?.length) {
      handleToggleSelectJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atsJobs]);

  useEffect(() => {
    return () => deselectJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    atsJobs,
    filteredAtsJobs,
    handleSelectAllJobs,
    handleToggleSelectJobs,
    handleMoveATSJobsToDraft,
    publishSelectedATSJobs,
    searchValue,
    setSearchValue,
    isLoading,
    someJobsSelected,
    unsyncedJobCount,
    anyJobsSelected,
    selectedJobsCount,
    allJobsSelected,
  };
};
