import { type WrappedFieldProps } from "redux-form";

import {
  Box,
  StyleAutocompleteDelimiterSeparatedOptions,
  StyleAutocompleteDelimiterSeparatedOptionsPaper,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type { LocationValue } from "@js/components/autocomplete-new/google-places/types";
import { GoogleComboboxMultipleField } from "@js/forms/fields/autocomplete/";
import { CheckboxField } from "@js/forms/fields/checkbox";

import { JobLocationExpandableTextField } from "../job-location-expandable-text-field";

import type { UseJobLocationFieldArg } from "./hook";
import { useJobLocationField } from "./hook";
import { PopularLocationsButtons } from "./job-popular-locations-buttons";

type JobLocationFieldProps = {
  locations_strongly_required: WrappedFieldProps;
  locations: UseJobLocationFieldArg["locations"];
};

export const JobLocationField = ({
  locations,
  locations_strongly_required,
}: JobLocationFieldProps) => {
  const {
    handleDisableOptionFromList,
    handleChange,
    renderOptionsHandler,
    renderTagsHandler,
    filterOptionsHandler,
  } = useJobLocationField({
    locations,
  });

  const hasLocations = !!locations.input.value?.length;
  const locationsInput = {
    ...locations.input,
    onChange: (value: unknown) => {
      handleChange(value as LocationValue[]);
    },
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" maxWidth={400}>
        <Typography
          component="label"
          variant="label"
          size="small"
          fontWeight={400}
          id="select-location-label-id"
          sx={{ mb: 0.75 }}
        >
          Select location
        </Typography>
        <GoogleComboboxMultipleField<false>
          id="locations"
          placesServiceTypes={"regions_without_sublocality"}
          openOnFocus
          label={undefined}
          useCustomLocations
          filterOptions={filterOptionsHandler}
          getOptionDisabled={handleDisableOptionFromList}
          component={StyleAutocompleteDelimiterSeparatedOptions}
          PaperComponent={StyleAutocompleteDelimiterSeparatedOptionsPaper}
          renderOption={renderOptionsHandler}
          renderTags={renderTagsHandler}
          locationIcon={false}
          input={locationsInput}
          meta={locations.meta}
          renderInput={(params, state) => {
            return (
              <JobLocationExpandableTextField
                {...params}
                aria-labelledby="select-location-label-id"
                placeholder={
                  !!locations.input.value?.length
                    ? ""
                    : "Select a country, state, or city"
                }
                error={!!state.isError}
                helperText={state.error}
              />
            );
          }}
        />
      </Box>
      <PopularLocationsButtons input={locationsInput} />
      <Box mt={1.5}>
        <CheckboxField
          disabled={!hasLocations}
          label={
            <Typography component="span" variant="paragraph" size="small">
              Require Talent to be authorized to work in the selected locations
            </Typography>
          }
          input={locations_strongly_required.input}
          meta={locations_strongly_required.meta}
        />
      </Box>
    </Box>
  );
};
