import { useMemo } from "react";

import { useGetPopularSkillsQuery } from "@js/apps/skills/api";

import { filterOutSelectedSkills } from "../helpers";
import type { PopularSkillOption } from "../types";

type UseGetPopularSkillsOptionsArg = {
  role: number | undefined;
  selectedSkills: number[];
  skillsLimit?: number;
};

export const useGetPopularSkillsOptions = ({
  role,
  selectedSkills,
  skillsLimit = 13,
}: UseGetPopularSkillsOptionsArg) => {
  const { data: popularSkills } = useGetPopularSkillsQuery({
    roleIds: role ? [role] : undefined,
  });

  const options: PopularSkillOption[] = useMemo(() => {
    if (!popularSkills?.length) return [];

    return filterOutSelectedSkills({
      skills: popularSkills,
      selectedSkills,
    })
      .slice(0, skillsLimit)
      .map((skill) => {
        return { value: skill.id, label: skill.name };
      });
  }, [popularSkills, selectedSkills, skillsLimit]);

  return {
    popularSkillsOptions: options,
    allPopularSkills: popularSkills || [],
  };
};
