import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _, { throttle } from "underscore";

import {
  ComboBoxMultiple,
  PaperWithVioletOptions,
  TextField,
} from "@hexocean/braintrust-ui-components";
import { SearchIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useEffectRef } from "@js/hooks/use-effect-ref";

import type { GetSkillsQueryParams } from "../../api";
import { useGetSkillsQuery } from "../../api";
import { NotFoundText } from "../../fields/components";
import { StyledSkillsAutocomplete } from "../../fields/styled";

export type SkillsAutocompleteProps = {
  value: number[];
  onChange: (skills: number[]) => void;
  error?: string;
};

export const SkillsAutocomplete = ({
  value,
  onChange,
  error,
}: SkillsAutocompleteProps) => {
  const [skillsQueryArg, setSkillsQueryArg] = useState<GetSkillsQueryParams>({
    search: "",
    page: 1,
    ordering: "name",
  });
  const listboxRef = useRef<HTMLElement>(null);

  const {
    data: skillsResponse,
    isLoading,
    isFetching,
  } = useGetSkillsQuery(skillsQueryArg);

  const currentPage = skillsQueryArg.page || 1;
  const skillsOptionsToDisplayCount = currentPage * SETTINGS.SKILLS_PER_PAGE;

  const handleSearchChangeDebounced = useMemo(() => {
    return _.debounce((newValue: string) => {
      setSkillsQueryArg((prev) => {
        return {
          ...prev,
          search: newValue,
          page: 1,
        };
      });
    }, 250);
  }, [setSkillsQueryArg]);

  useEffect(() => {
    return () => handleSearchChangeDebounced.cancel();
  }, [handleSearchChangeDebounced]);

  const skillsData = skillsResponse?.results ?? [];

  const skillOptionsToDisplay = useMemo(() => {
    const skills = skillsResponse?.results ?? [];
    const skillIds = skills
      .map((skill) => skill.id)
      .slice(0, skillsOptionsToDisplayCount);

    return skillIds;
  }, [skillsResponse, skillsOptionsToDisplayCount]);

  const optionsWithValue = useMemo(() => {
    return _.uniq([...skillOptionsToDisplay, ...value]);
  }, [value, skillOptionsToDisplay]);

  const fetchNextPage = useCallback(() => {
    setSkillsQueryArg((prev) => {
      const prevPage = prev.page || 1;
      return { ...prev, page: prevPage + 1 };
    });
  }, []);

  const hasNextPage = !!skillsResponse?.next;
  const handleScroll = () => {
    if (!hasNextPage || isFetching || !listboxRef.current) {
      return;
    }
    const target = listboxRef.current;
    const listHeight = target.scrollHeight;
    const offsetBottom = target.scrollTop + target.offsetHeight;
    const shouldFetch = listHeight - offsetBottom < 200;
    if (!shouldFetch) {
      return;
    }

    fetchNextPage();
  };

  const handleScrollRef = useEffectRef(handleScroll);
  const throttledHandleScroll = useMemo(() => {
    return throttle(() => handleScrollRef.current(), 200);
  }, [handleScrollRef]);

  const handleInputChange = (_event: unknown, inputValue: string) => {
    handleSearchChangeDebounced(inputValue);
  };

  return (
    <ComboBoxMultiple<number, true>
      id="skillsCombobox"
      value={value}
      options={optionsWithValue}
      onInputChange={handleInputChange}
      initialTaxonomiesLoading={isLoading}
      component={StyledSkillsAutocomplete}
      PaperComponent={PaperWithVioletOptions}
      onChange={(_ev, values) => {
        if (!values) {
          return;
        }
        const unique = _.uniq(values, false);
        onChange(unique);
      }}
      onKeyDownCapture={(ev) => {
        if (ev.key === "ArrowDown" && isFetching) {
          ev.stopPropagation();
        }
      }}
      ListboxProps={{
        ref: listboxRef,
        onScroll: throttledHandleScroll,
      }}
      onHighlightChange={(_ev, option) => {
        const optionsCount = skillOptionsToDisplay.length;
        const lastOption = skillOptionsToDisplay[optionsCount - 1];
        const isLastOptionHighligthed = lastOption === option;
        if (isLastOptionHighligthed) {
          handleScroll();
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="small"
            helperText={error}
            error={!!error}
            placeholder="Search skills"
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
            }}
          />
        );
      }}
      noOptionsText={<NotFoundText />}
      getOptionLabel={(option) => {
        const optionData = skillsData?.find((skill) => skill.id === option);
        if (!optionData) {
          return String(option);
        }
        return optionData.name;
      }}
      groupBy={(option) => {
        const optionData = skillsData?.find((skill) => skill.id === option);
        if (!optionData) {
          return "";
        }

        const firstLetter = optionData.name
          .replace(/^(\.)/, "")
          .charAt(0)
          .toUpperCase();
        return /[0-9]/.test(firstLetter) ? "0-9" : firstLetter;
      }}
      renderOption={(props, option) => {
        const optionData = skillsData?.find((skill) => skill.id === option);
        if (!optionData) {
          return null;
        }

        return (
          <li {...props} key={option}>
            {optionData.name}
          </li>
        );
      }}
      disableClearable
      disableValueRemoval
      filterSelectedOptions
      displayAllOptions
    />
  );
};
