import type { ComponentProps } from "react";

import { Button } from "@hexocean/braintrust-ui-components";
import { AddIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useMergeLinkComponent } from "@js/apps/employer/hooks/merge-link-component";
import { useAppDispatch } from "@js/hooks";

import { clickAddIntegrationATS } from "../../actions";

export const OpenMergeComponentButton = ({
  sx,
  onIntegrationSuccess,
}: {
  sx?: ComponentProps<typeof Button>["sx"];
  onIntegrationSuccess?: () => void;
}) => {
  const {
    initializeATSIntegration,
    isATSIntegrationDisabled,
    isATSIntegrationInitializing,
  } = useMergeLinkComponent({ onSuccess: onIntegrationSuccess });

  const dispatch = useAppDispatch();

  const handleAddAtsClick = () => {
    dispatch(clickAddIntegrationATS());
    initializeATSIntegration();
  };

  return (
    <Button
      variant="primary"
      disabled={isATSIntegrationDisabled}
      onClick={handleAddAtsClick}
      sx={sx}
      style={{ padding: "11px 20px" }}
    >
      {isATSIntegrationInitializing ? (
        "Preparing ATS integration window..."
      ) : (
        <>
          <AddIcon /> Add ATS integration
        </>
      )}
    </Button>
  );
};
