import { Avatar, Button } from "@hexocean/braintrust-ui-components";
import { ArrowTopRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { viewProfileHoverStateClicked } from "@js/apps/give-and-get-help/actions";
import type { ProfileCardHeaderProps } from "@js/apps/give-and-get-help/components/profile-card/profile-card.types";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";

export const ProfileCardHeader = ({
  avatarData,
  coverPhoto,
  talentId,
  showMessageButton,
}: ProfileCardHeaderProps) => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const handleProfileViewClick = () => {
    dispatch(viewProfileHoverStateClicked({ talent_id: talentId }));
  };
  const showMessageCta = showMessageButton && user && avatarData.id !== user.id;
  const { openMessengerModal } = useOpenMessengerModal();

  return (
    <div className="profile-card__header">
      <img
        src={
          coverPhoto ||
          `${SETTINGS.STATIC_URL}give-get-help/profile-card-background.png`
        }
        alt="user cover"
        className="profile-card__header-cover"
        width="auto"
        height="75"
      />
      <div className="profile-card__header-avatar">
        <Avatar src={avatarData} size="full" />
      </div>

      <div className="profile-card__header-btn-wrapper">
        {showMessageCta && user && (
          <Button
            variant={coverPhoto ? "black-outlined" : "secondary"}
            shape="squared"
            size="x-small"
            onClick={() =>
              openMessengerModal({
                context: {
                  participants: [avatarData.id, user.id],
                },
              })
            }
            className="profile-card__header-message-btn"
          >
            Message
          </Button>
        )}

        <Button
          to={`/talent/${talentId}`}
          RouterLink={RouterLink}
          variant="primary"
          shape="squared"
          size="x-small"
          fontWeight={400}
          endIcon={<ArrowTopRightIcon />}
          onClick={handleProfileViewClick}
          className="profile-card__header-view-btn"
        >
          View profile
        </Button>
      </div>
    </div>
  );
};
