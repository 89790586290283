import { useEffect, useState } from "react";

import { ProfileCompleteConfirmationModalContent } from "@js/apps/freelancer/components/profile-complete-confirmation-modal";
import { jobsApi } from "@js/apps/jobs/api";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { useCurrentFreelancerProfileCompletion } from "../use-current-freelancer-profile-completion";

export const useProfileCompletionSuccess = () => {
  const dispatch = useAppDispatch();
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer.freelancerProfile,
  );
  const isApproved = !!freelancerProfile?.approved;
  const hasAlreadyBid = !!freelancerProfile?.has_bid;
  const { data: profileCompletion } = useCurrentFreelancerProfileCompletion({
    skip: isApproved || hasAlreadyBid,
  });
  const [wasSuccessModalShown, setWasSuccessModalShown] = useState(false);
  const [wasProfileIncomplete, setWasProfileIncomplete] = useState(false);

  const isProfileCompleted =
    !!profileCompletion &&
    !!profileCompletion.profile_completion_hit_at &&
    profileCompletion.completion_percent > 99;

  const hasCompletedProfile = isProfileCompleted && wasProfileIncomplete;
  const shouldOpenSuccessModal =
    !hasAlreadyBid &&
    !isApproved &&
    !wasSuccessModalShown &&
    hasCompletedProfile;

  useEffect(() => {
    if (!profileCompletion || isProfileCompleted) {
      return;
    }

    setWasProfileIncomplete(true);
  }, [profileCompletion, isProfileCompleted]);

  useEffect(() => {
    if (!shouldOpenSuccessModal) {
      return;
    }

    setWasSuccessModalShown(true);

    // Invalidate jobs as job.can_bid relies on profile completion
    dispatch(jobsApi.util.invalidateTags(["Jobs"]));
    openSuccessModal();
  }, [shouldOpenSuccessModal, dispatch]);
};

const openSuccessModal = () =>
  ModalInstance.open({
    closeButton: false,
    keepOnBackdropClick: true,
    padding: false,
    children: <ProfileCompleteConfirmationModalContent />,
  });
