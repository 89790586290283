import { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import type { RoleFormType } from "@js/apps/common/components/filters/forms/role-form";
import type { SkillsFormType } from "@js/apps/common/components/filters/forms/skills-form";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components";
import { SkillsAutocomplete } from "@js/apps/skills/components/skills-autocomplete";

import { PopularSkills } from "./popular-skills";
import { SelectedSkills } from "./selected-skills";
import { useSkillsFilterContent } from "./use-skills-filter-content";

export type SkillsFilterContentProps = {
  role: TypedWrappedFieldProps<RoleFormType["role"]>;
  skills: TypedWrappedFieldProps<SkillsFormType["skills"]>;
  defaultRole?: RoleFormType["role"];
};

export const SkillsFilterContent = ({
  role,
  skills,
  defaultRole,
}: SkillsFilterContentProps) => {
  const {
    toggleSkill,
    applySkillFilter,
    clearSkillFilter,
    onChangeSearchSkill,
    isFetchingPopularSkills,
    selectedRolesData,
    filteredSkills,
    skillsValue,
  } = useSkillsFilterContent({ role, skills, defaultRole });

  const selectedRolesText = useMemo(() => {
    if (!selectedRolesData?.length) {
      return "";
    }

    return selectedRolesData.map((roleData) => roleData.name_plural).join(", ");
  }, [selectedRolesData]);

  const error = skills.meta.error?.skills
    ? String(skills.meta.error?.skills)
    : undefined;

  return (
    <JobPopoverFilterButton.Content
      noBottomPadding
      onReset={clearSkillFilter}
      onApply={applySkillFilter}
      noHeightRestrict={true}
    >
      <Box mb={0.5}>
        <SkillsAutocomplete
          value={skills.input.value}
          onChange={onChangeSearchSkill}
          error={error}
        />
      </Box>

      <SelectedSkills skillsValue={skillsValue} toggleSkill={toggleSkill} />
      <Box my={1}>
        <Typography component="span" size="medium">
          Popular skills
        </Typography>
        {!!selectedRolesText && (
          <Typography component="span" size="medium">
            &nbsp;for <b>{selectedRolesText}</b>
          </Typography>
        )}
      </Box>
      <Box sx={{ overflow: "auto", pb: 2 }}>
        <PopularSkills
          filteredSkills={filteredSkills}
          isFetchingPopularSkills={isFetchingPopularSkills}
          toggleSkill={toggleSkill}
        />
      </Box>
    </JobPopoverFilterButton.Content>
  );
};
