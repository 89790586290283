import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  AddCircleOutlineIcon,
  ArrowRightIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { EditPenIcon } from "@hexocean/braintrust-ui-components/Icons";
import { openSpaceInviteContactsModal } from "@js/apps/spaces/components/invite-to-space-modal";
import type { Space } from "@js/types/spaces";
import { shortenValue } from "@js/utils";

import { useIsSpaceMember } from "../../hooks/use-is-space-member";
import { openJoinThisSpaceModal } from "../join-the-space-modal/join-this-space-modal";
import { SpaceActivityContent } from "../space-activity";
import { ActionMenu } from "../space-header/action-menu";
import type { SpaceMemberAvatarData } from "../space-members";

import { openAboutSpaceDescriptionModal } from "./about-space-description-modal";
import { openAboutThisSpaceModalForm } from "./edit-modal-form";

import styles from "./styles.module.scss";

export const MAX_DESCRIPTION_LENGTH = 100;

export type AboutThisSpaceComponentProps = {
  space: Space;
  members: SpaceMemberAvatarData[];
  membersCount: number;
  isDescriptionPreview?: boolean;
  isMember: boolean;
  isAdmin: boolean;
  areNotificationsTurnOn?: boolean;
};

export const AboutThisSpace = ({
  space,
  members,
  membersCount,
  isDescriptionPreview,
  isMember,
  isAdmin,
  areNotificationsTurnOn,
}: AboutThisSpaceComponentProps) => {
  const handleEditButtonClick = () => {
    if (!isAdmin) return;

    openAboutThisSpaceModalForm({
      id: space.id,
      description: space.description,
    });
  };

  return (
    <Box
      className={styles.container}
      display="flex"
      flexDirection="column"
      rowGap={1}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography component="h3" variant="paragraph" size="large">
          About this space
        </Typography>
        {isAdmin && (
          <IconButton
            size="x-small"
            variant="black-outlined"
            aria-label="edit-about-this-space"
            onClick={handleEditButtonClick}
          >
            <EditPenIcon />
          </IconButton>
        )}
      </Box>
      <Typography component="p" variant="paragraph" size="medium">
        {isDescriptionPreview
          ? shortenValue(space.description, MAX_DESCRIPTION_LENGTH)
          : space.description}
      </Typography>
      {isDescriptionPreview && (
        <AboutThisSpaceContent
          space={space}
          members={members}
          membersCount={membersCount}
          isMember={isMember}
          isAdmin={isAdmin}
          areNotificationsTurnOn={areNotificationsTurnOn}
        />
      )}
    </Box>
  );
};

type AboutThisSpaceContentProps = Pick<
  AboutThisSpaceComponentProps,
  | "space"
  | "members"
  | "membersCount"
  | "isMember"
  | "isAdmin"
  | "areNotificationsTurnOn"
>;

const AboutThisSpaceContent = ({
  space,
  members,
  membersCount,
  isMember,
  isAdmin,
  areNotificationsTurnOn,
}: AboutThisSpaceContentProps) => {
  const navigate = useNavigate();
  const { isSpaceMember } = useIsSpaceMember({ spaceId: space.id });

  const handleReadMoreClick = () => {
    if (isAdmin) {
      navigate(`/spaces/${space.id}/about/`);
    } else {
      openAboutSpaceDescriptionModal({
        description: space.description,
      });
    }
  };

  const handleInviteClick = () => {
    if (!isSpaceMember) {
      openJoinThisSpaceModal(space);
    } else {
      openSpaceInviteContactsModal({
        spaceId: space.id,
      });
    }
  };

  const membersSrc = `/spaces/${space.id}/people/`;
  const shouldShowReadMore = space.description.length > MAX_DESCRIPTION_LENGTH;

  return (
    <>
      {shouldShowReadMore && (
        <Box className={styles.readMoreWrapper}>
          <Button
            className={styles.readMoreWrapperBtn}
            onClick={handleReadMoreClick}
            variant="no-padding"
          >
            Read more <ArrowRightIcon />
          </Button>
        </Box>
      )}
      <SpaceActivityContent
        space={space}
        spaceType={space.visibility}
        created={space.created}
        lastWeekPosts={space.number_of_posts_for_last_7_days}
        lastMonthPosts={space.number_of_posts_for_last_30_days}
        members={members}
        membersCount={membersCount}
        membersSrc={membersSrc}
      />
      <Box display="flex" alignItems="center" gap={1}>
        <Button variant="primary" onClick={handleInviteClick} fullWidth>
          <AddCircleOutlineIcon className={styles.svg} />
          Invite members
        </Button>
        {isMember && (
          <ActionMenu
            areNotificationsTurnOn={areNotificationsTurnOn}
            isAdmin={isAdmin}
          />
        )}
      </Box>
    </>
  );
};
