import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import { useGetFreelancerLocationMismatchQuery } from "@js/apps/admin/api";
import { useGetJobQuery } from "@js/apps/jobs/api";
import { useBidListFilters } from "@js/apps/jobs/components/filtering-actions";
import { useIdParam } from "@js/hooks/use-id-param";
import type { EmployerBid, EmployerBidListBid } from "@js/types/jobs";

import { useFetchBidQuery, useFetchBidsQuery } from "../../api";

export const useBidDrawer = () => {
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const jobId = useIdParam();
  const bidId = useIdParam("bidId");
  const { filters: bidFilters } = useBidListFilters();

  const { currentData: bidDetails } = useFetchBidQuery(
    { bidId: bidId as number },
    { skip: !bidId || !jobId },
  );

  const { data: bids, isFetching: isBidsFetching } = useFetchBidsQuery(
    { job: jobId as number, ...bidFilters },
    { skip: !jobId },
  );

  const { data: job } = useGetJobQuery(
    { id: jobId as number },
    { skip: !jobId },
  );

  const bidIds = useMemo(() => bids?.results.map(({ id }) => id) || [], [bids]);

  const bidToDisplay: EmployerBid | EmployerBidListBid | undefined =
    useMemo(() => {
      if (!bidId) {
        return;
      }

      if (bidDetails) {
        return bidDetails;
      }

      const bidToDisplayInBidsList = bids?.results.find(
        (bid) => bid.id === bidId,
      );

      return bidToDisplayInBidsList;
    }, [bidDetails, bidId, bids]);

  const { data: locationMismatchData, isLoading: isLocationMismatchLoading } =
    useGetFreelancerLocationMismatchQuery(
      bidToDisplay?.freelancer.id ?? skipToken,
    );

  const currentBidIndex = useMemo(() => {
    const index = bidIds.findIndex((id) => id === Number(bidId));
    if (index === -1) {
      return;
    }

    return index;
  }, [bidId, bidIds]);

  const totalBidsCount = bidIds.length;

  const goToPreviousBid = () => {
    const previousBidIndex = getPreviousBidIndex({
      totalBidsCount,
      currentBidIndex,
    });
    if (typeof previousBidIndex === "undefined") {
      return;
    }

    navigate(`/jobs/${jobId}/proposals/${bidIds[previousBidIndex]}${search}`, {
      state: { ...state, noScroll: true },
    });
  };

  const goToNextBid = () => {
    const nextBidIndex = getNextBidIndex({
      totalBidsCount,
      currentBidIndex,
    });
    if (typeof nextBidIndex === "undefined") {
      return;
    }

    navigate(`/jobs/${jobId}/proposals/${bidIds[nextBidIndex]}${search}`, {
      state: { ...state, noScroll: true },
    });
  };

  return {
    currentBidIndex,
    bidToDisplay,
    totalBidsCount,
    goToNextBid,
    goToPreviousBid,
    locationMismatchData,
    job,
    isBidsFetching,
    isLocationMismatchLoading,
  };
};

const getPreviousBidIndex = ({
  totalBidsCount,
  currentBidIndex,
}: {
  totalBidsCount: number;
  currentBidIndex: number | undefined;
}) => {
  if (typeof currentBidIndex === "undefined") {
    return 0;
  }
  if (totalBidsCount <= 1) {
    return;
  }

  if (currentBidIndex === 0) {
    return totalBidsCount - 1;
  }

  return currentBidIndex - 1;
};

const getNextBidIndex = ({
  totalBidsCount,
  currentBidIndex,
}: {
  totalBidsCount: number;
  currentBidIndex: number | undefined;
}) => {
  if (typeof currentBidIndex === "undefined") {
    return 0;
  }

  if (totalBidsCount <= 1) {
    return;
  }

  if (currentBidIndex === totalBidsCount - 1) {
    return 0;
  }

  return currentBidIndex + 1;
};
