import React from "react";
import cs from "classnames";

import { useAnchorRedirectWarning } from "@js/hooks/anchor-redirect-warning";

import type { prepareDOMInputFun, SanitizeConfig } from "../../services";
import { prepareDOMInput } from "../../services";

type SanitizeProps = {
  className?: string;
  keepOriginalStyles?: boolean;
  unescape?: boolean;
  markdown?: boolean;
  prepareInput?: prepareDOMInputFun;
  input: string | null | undefined;
  config?: SanitizeConfig;
  style?: React.CSSProperties;
  whiteSpacePreLine?: boolean;
  postSanitizeContent?: (sanitizedText: string) => string;
};

export const Sanitize = ({
  className = "",
  keepOriginalStyles = false,
  unescape = false,
  markdown = true,
  prepareInput = prepareDOMInput,
  whiteSpacePreLine = false,
  input,
  config,
  postSanitizeContent = (sanitizedText) => sanitizedText,
  ...props
}: SanitizeProps): JSX.Element | null => {
  const { anchorWrapperRef } = useAnchorRedirectWarning<HTMLDivElement>();
  if (!input) {
    return null;
  }

  return (
    <div
      data-testid="sanitized"
      className={cs("sanitized-content", className, {
        "no-p-margin": !keepOriginalStyles,
        "text-content": !className,
        "white-space--pre-line": whiteSpacePreLine,
        "white-space--normal": !whiteSpacePreLine,
      })}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: postSanitizeContent(
          prepareInput(input || "", {
            markdown,
            unescape,
            sanitize: config,
          }),
        ),
      }}
      ref={anchorWrapperRef}
      {...props}
    />
  );
};
