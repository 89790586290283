import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useUser } from "@js/apps/common/hooks";
import {
  fetchFreelancerJobsSelect,
  fetchFreelancerProfile,
} from "@js/apps/freelancer/actions";
import { AddressWarning } from "@js/apps/freelancer/components/address-warning";
import { openOTPDisabledWarningModal } from "@js/apps/settings/components/otp-auth";
import { fetchWithdrawalMethods } from "@js/apps/withdrawal/actions";
import TaxFormRequiredWarningModal from "@js/apps/withdrawal/components/tax-form-required-warning";
import WithdrawalMethodWarningModal from "@js/apps/withdrawal/components/withdrawal-method-warning";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { canFreelancerUseJobRelatedFeatures } from "@js/routes/utils";
import type {
  Recipient,
  StripeWithdrawalMethod,
  TransferWiseWithdrawalMethod,
  WithdrawalState,
} from "@js/types/withdrawals";

export const useCanUserCreateInvoice = () => {
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isLoadingFinished, setIsLoadingFinished] = useState(false);
  const user = useUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { state: locationState } = useLocation();

  const jobSelectList = useAppSelector(
    (state) => state.freelancer.jobSelectList,
  );
  const withdrawalMethods = useAppSelector(
    (state) => (state.withdrawal as WithdrawalState).withdrawalMethods,
  );
  const isFetchingWithdrawalMethods = useAppSelector(
    (state) => (state.withdrawal as WithdrawalState).fetchingWithdrawalMethods,
  );
  const isFetchingFreelancerProfile = useAppSelector(
    (state) => state.freelancer.fetchingFreelancerProfile,
  );
  const hasDefaultWithdrawalMethod = !!withdrawalMethods.filter(
    (method) => method.is_default,
  ).length;

  const navigateOptions = useMemo(
    () => ({
      state: { disableRemountIfTheSameLocation: true },
      replace: true,
    }),
    [],
  );

  const prevPath = locationState?.prevPath;

  const canUseFeature = canFreelancerUseJobRelatedFeatures(user);
  const freelancerId = user?.freelancer;

  const someJobsCanHaveInvoice = jobSelectList.some(
    (job) => job.invoice_can_be_created,
  );
  const offPlatformPaymentEnabled =
    user?.freelancer_braintrust_pays_talent_off_platform_enabled ||
    someJobsCanHaveInvoice;

  const hasCountrySet = user?.address?.country;

  const loading = isFetchingWithdrawalMethods || isFetchingFreelancerProfile;

  const fetchNecessaryData = useCallback(async () => {
    if (freelancerId) {
      await dispatch(fetchWithdrawalMethods());
      await dispatch(fetchFreelancerProfile(freelancerId));
      await dispatch(fetchFreelancerJobsSelect());
      setIsDataFetched(true);
    }
  }, [dispatch, freelancerId]);

  useEffect(() => {
    fetchNecessaryData();
  }, [fetchNecessaryData]);

  useEffect(() => {
    if (!isDataFetched) return;

    if (
      !prevPath &&
      (!hasDefaultWithdrawalMethod || !hasCountrySet || !canUseFeature)
    ) {
      navigate("/", { replace: true });
    } else if (!user?.is_otp_enabled && prevPath) {
      openOTPDisabledWarningModal();
      navigate(prevPath, navigateOptions);
    } else if (
      (user?.is_w9_form_required || user?.is_w8_form_required) &&
      prevPath
    ) {
      TaxFormRequiredWarningModal.Instance.open();
      navigate(prevPath, navigateOptions);
    } else if (
      !hasDefaultWithdrawalMethod &&
      !offPlatformPaymentEnabled &&
      !someJobsCanHaveInvoice &&
      prevPath
    ) {
      WithdrawalMethodWarningModal.Instance.open();
      navigate(prevPath, navigateOptions);
    } else if (!hasCountrySet && prevPath) {
      ModalInstance.open({
        children: <AddressWarning closeModal={ModalInstance.close} />,
        className: "general-warning",
      });
      navigate(prevPath, navigateOptions);
    } else if (
      !offPlatformPaymentEnabled &&
      hasNoActiveStripeWithdrawalMethods(withdrawalMethods) &&
      !someJobsCanHaveInvoice &&
      prevPath
    ) {
      WithdrawalMethodWarningModal.Instance.open();
      navigate(prevPath, navigateOptions);
    }

    setIsLoadingFinished(true);
  }, [
    someJobsCanHaveInvoice,
    canUseFeature,
    hasCountrySet,
    hasDefaultWithdrawalMethod,
    isDataFetched,
    loading,
    navigate,
    navigateOptions,
    offPlatformPaymentEnabled,
    prevPath,
    user?.is_otp_enabled,
    user?.is_w8_form_required,
    user?.is_w9_form_required,
    withdrawalMethods,
  ]);

  return { isLoadingFinished };
};

const isStripeWithdrawalMethod = (
  withdrawalMethod: StripeWithdrawalMethod | TransferWiseWithdrawalMethod,
): withdrawalMethod is StripeWithdrawalMethod =>
  withdrawalMethod.content_type ===
  ENUMS.WithdrawalContentType.StripeWithdrawalMethod;

const hasNoActiveStripeWithdrawalMethods = (
  withdrawalMethods: (StripeWithdrawalMethod | TransferWiseWithdrawalMethod)[],
): boolean => {
  const isPayoutEnabledForRecipient = (recipient: Recipient): boolean =>
    recipient.account_onboarded;
  const areAllElementsStripeWithdrawalMethods = withdrawalMethods.every(
    (method) => isStripeWithdrawalMethod(method),
  );
  if (areAllElementsStripeWithdrawalMethods) {
    return withdrawalMethods.every((withdrawalMethod) => {
      if ("recipients" in withdrawalMethod.method) {
        return !withdrawalMethod.method.recipients?.some(
          isPayoutEnabledForRecipient,
        );
      }
      return false;
    });
  }
  return false;
};
