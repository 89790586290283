import { useEffect, useMemo, useState } from "react";
import { Field, reduxForm } from "redux-form";

import {
  Box,
  Button,
  Divider,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";
import { REQUEST_FORM_ID } from "@js/apps/give-and-get-help/components/request-refund-modal/constants";
import type { RequestRefundModalFormData } from "@js/apps/give-and-get-help/components/request-refund-modal/types";
import { getHelpOfferTotalBudget } from "@js/apps/give-and-get-help/utils/common";
import { Form } from "@js/forms/components";
import { TextareaField } from "@js/forms/fields/textarea/textarea";
import { requiredWithSpecificMessage } from "@js/forms/validators";
import type { HelpOffer } from "@js/types/give-and-get-help";

import { RequestRefundErrorSection } from "./request-refund-error-section";

export type RequestRefundModalProps = {
  onCancel?: () => void;
  helperFirstName?: string;
  offer: { budget: HelpOffer["budget"]; fee: HelpOffer["fee"] };
};

export const RequestOfferRefundFormModal = reduxForm<
  RequestRefundModalFormData,
  RequestRefundModalProps
>({
  form: REQUEST_FORM_ID,
})(({
  error,
  submit,
  submitting,
  helperFirstName,
  offer,
  invalid,
  dirty,
  submitFailed,
}) => {
  const [showErrorSection, setShowErrorSection] = useState(false);

  useEffect(() => {
    if (invalid && (dirty || submitFailed)) {
      setShowErrorSection(true);
    }

    if (!invalid) {
      setShowErrorSection(false);
    }
  }, [invalid, dirty, submitFailed]);

  const requiredValidation = useMemo(
    () =>
      requiredWithSpecificMessage(
        `Please include a message to ${helperFirstName}`,
      ),
    [helperFirstName],
  );

  const totalTokensToRefund = getHelpOfferTotalBudget(offer);

  return (
    <Form onSubmit={submitting ? null : submit} error={error}>
      <Typography mb={3} component="p" variant="label" size="large">
        Request a refund
      </Typography>
      <Typography
        mb={3}
        component="p"
        variant="title"
        size="medium"
        fontWeight={400}
      >
        Ask {helperFirstName} for a refund
      </Typography>
      <RefundModalBulletpoints
        data={[
          {
            icon: "💬",
            background: "var(--medium-blue)",
            content:
              "Mention your concerns. Is there a way they could resolve the issue instead of a refund?",
          },
          {
            icon: "💰",
            background: "var(--medium-green)",
            content: (
              <>
                If they approve your request, you’ll receive a full refund of{" "}
                <b>
                  <BraintrustIcon
                    sx={{
                      fontSize: "inherit",
                      verticalAlign: "text-bottom",
                    }}
                  />{" "}
                  {totalTokensToRefund} BTRST
                </b>{" "}
                to your wallet.
              </>
            ),
          },
        ]}
      />
      <Divider
        color="beige"
        sx={{
          margin: "32px 0",
        }}
      />
      <Field
        className="refund-reason-field"
        variant="outlined"
        maxLength={1000}
        maxLengthCounterThreshold={900}
        label={`Message to ${helperFirstName}`}
        hideError
        placeholder="Explain why you are requesting a refund..."
        InputLabelProps={{ shrink: true }}
        id="refund_requested_reason"
        name="refund_requested_reason"
        validate={[requiredValidation]}
        rows={3}
        component={TextareaField}
      />
      <Box className="request-refund-modal__footer">
        {showErrorSection && <RequestRefundErrorSection />}

        <Button variant="primary" shape="squared" onClick={submit}>
          Request refund
        </Button>
      </Box>
    </Form>
  );
});

type RefundModalExplainBulletPoint = {
  icon: string;
  content: string | JSX.Element;
  background: string;
};

const RefundModalBulletpoints = ({
  data,
}: {
  data: RefundModalExplainBulletPoint[];
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {data.map((item) => (
        <Box display="flex" key={item.icon} gap={3} alignItems="center">
          <Box
            width="48px"
            height="48px"
            bgcolor={item.background}
            borderRadius="100%"
          >
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="20px"
            >
              {item.icon}
            </Box>
          </Box>
          <Typography component="p" variant="paragraph" size="large" flex={1}>
            {item.content}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
