import { useCallback, useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { submit } from "redux-form";

import type { RoleFormType } from "@js/apps/common/components/filters/forms/role-form";
import { useGetRolesQuery } from "@js/apps/roles/api";
import { useFetchRolePopularSkills } from "@js/apps/skills/hooks";

type UseSkillsFilterProps = {
  currentRole: TypedWrappedFieldProps<RoleFormType["role"]>;
  skills: TypedWrappedFieldProps<number[]>;
  defaultRole?: RoleFormType["role"];
};

export const useSkillsFilter = ({
  currentRole,
  skills,
  defaultRole,
}: UseSkillsFilterProps) => {
  const selectedRole = useMemo(() => {
    const currentRoleIds = currentRole.input.value;
    const hasCurrentRole = Boolean(currentRole.input.value.length);

    return hasCurrentRole ? currentRoleIds : defaultRole;
  }, [defaultRole, currentRole.input.value]);

  const { skillsValue, toggleSkill, clearSkillFilter } =
    useSkillsSelection(skills);

  const { popularSkills, isFetching: isFetchingPopularSkills } =
    useFetchRolePopularSkills(selectedRole);
  const { data: roles } = useGetRolesQuery();

  const onChangeSearchSkill = (changeSkills: number[] | null) => {
    if (!changeSkills) {
      return;
    }

    const skill: number | undefined = changeSkills[changeSkills.length - 1];
    if (!skill) {
      return;
    }

    toggleSkill(skill);
  };

  const applySkillFilter = useCallback(() => {
    skills.meta.dispatch(submit(skills.meta.form));
  }, [skills.meta]);

  return {
    selectedRole,
    toggleSkill,
    popularSkills,
    applySkillFilter,
    clearSkillFilter,
    onChangeSearchSkill,
    skillsValue,
    roles,
    isFetchingPopularSkills,
  };
};

const useSkillsSelection = (skills: UseSkillsFilterProps["skills"]) => {
  const skillsValue = useMemo(() => {
    if (!skills.input.value) return [];
    if (Array.isArray(skills.input.value)) return skills.input.value;
    return String(skills.input.value).split(",").filter(Boolean).map(Number);
  }, [skills.input.value]);

  const toggleSkill = useCallback(
    (skillId: number) => {
      if (skillsValue.includes(skillId)) {
        return skills.input.onChange(
          skillsValue.filter((id) => id !== skillId),
        );
      }
      return skills.input.onChange([...skillsValue, skillId]);
    },
    [skills.input, skillsValue],
  );

  const clearSkillFilter = useCallback(() => {
    skills.input.onChange([]);
  }, [skills.input]);

  return { skillsValue, toggleSkill, clearSkillFilter };
};
