import { Navigate } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import {
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { SpaceShipIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { useIdParam } from "@js/hooks/use-id-param";
import { AppLayout } from "@js/layouts/app";
import { assertUnreachable } from "@js/utils";

import { useGetFreelancerOfferQuery } from "../../api";
import { TalentOfferChecklist } from "../../components/talent-offer-checklist";

export const TalentOfferAcceptedSuccessPage = () => {
  return (
    <AppLayout
      bgcolor="var(--soft-green)"
      OverwriteContentComponent={TalentOfferContent}
      children={null}
    />
  );
};

const TalentOfferContent = () => {
  const offerId = useIdParam("offerId");

  const { data: offer, isFetching: isOfferLoading } =
    useGetFreelancerOfferQuery(offerId ?? skipToken, {
      refetchOnMountOrArgChange: true,
    });

  if (!offerId) {
    return <Navigate to="/jobs" />;
  }

  if (isOfferLoading || !offer) {
    return <Loader centered />;
  }

  if (offer.status !== ENUMS.OfferStatus.ACCEPTED) {
    return <Navigate to="/jobs" />;
  }

  return (
    <>
      <BgStripeWithShip />
      <div className="app-layout-content">
        <div className="max-width-800">
          <Typography component="h2" variant="title" size="small" mb={2}>
            You just accepted an offer! Congrats on your new gig 🎉 🙌
          </Typography>
          <Typography component="h4" variant="paragraph" mb={6}>
            {getAcceptedOfferText(offer.job.job_type)}
          </Typography>
          <TalentOfferChecklist offer={offer} />
          <Button
            variant="positive-2"
            shape="squared"
            size="medium"
            to="/talent/dashboard/my_jobs/offers/"
            RouterLink={RouterLink}
          >
            Back to My work
          </Button>
        </div>
      </div>
    </>
  );
};

const BgStripeWithShip = () => {
  return (
    <Box
      height="261px"
      width="100%"
      position="relative"
      bgcolor="var(--soft-yellow)"
      sx={{
        backgroundImage: `url(${SETTINGS.STATIC_URL}jobs/circles-horizontal.svg)`,
      }}
    >
      <SpaceShipIcon
        sx={{
          width: "272px",
          height: "120px",
          position: "absolute",
          top: "60px",
          left: "80px",
        }}
      />
    </Box>
  );
};

const getAcceptedOfferText = (jobType: EnumType<typeof ENUMS.JobType>) => {
  switch (jobType) {
    case ENUMS.JobType.FREELANCE:
    case ENUMS.JobType.GRANT:
      return (
        <>
          Nice job! Make sure you communicate with the Client about next steps,
          and <b>don’t forget to review the items below</b> to get set up for
          payments and taxes, review payment terms, and more.
        </>
      );

    case ENUMS.JobType.DIRECT_HIRE:
      return (
        <>
          Nice job! As you get ready to start your new role, make sure you
          communicate with the Client about next steps and onboarding tasks. And
          remember, you won’t invoice Braintrust for this job—the employer will
          pay you directly. Best of luck in your new job!
        </>
      );

    default:
      assertUnreachable(jobType);
      return null;
  }
};
