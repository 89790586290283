import { useState } from "react";

import {
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

type DeletePostModalContentProps = {
  isPostAuthor: boolean;
  isCelebratoryPost?: boolean;
  deletePost: () => Promise<unknown>;
  closeModal: () => void;
};

export const DeletePostModalContent = ({
  isPostAuthor,
  isCelebratoryPost,
  deletePost,
  closeModal,
}: DeletePostModalContentProps) => {
  const [isPending, setIsPending] = useState(false);
  const label = isPostAuthor ? "Delete your post" : "Delete this post";

  const title = isPostAuthor
    ? "Are you sure you want to permanently delete your post?"
    : "Are you sure you want to permanently delete this post?";

  const cta = isPostAuthor ? "Delete my post" : "Delete this post";

  return (
    <Box display="flex" flexDirection="column" padding={3.2} maxWidth={674}>
      <Typography component="p" size="large" fontWeight={500} mb={3}>
        {label}
      </Typography>
      <Box>
        <Typography component="p" variant="title" fontWeight={400} mb={1}>
          {title}
        </Typography>
        <DeletePostDescription
          isCelebratoryPost={isCelebratoryPost}
          isPostAuthor={isPostAuthor}
        />
      </Box>
      <Box ml="auto">
        <Button
          variant="destructive"
          shape="squared"
          disabled={isPending}
          onClick={async () => {
            setIsPending(true);
            await deletePost();
            closeModal();
          }}
          endIcon={isPending ? <Loader size={20} /> : undefined}
        >
          {cta}
        </Button>
      </Box>
    </Box>
  );
};

const DeletePostDescription = ({
  isCelebratoryPost,
  isPostAuthor,
}: {
  isCelebratoryPost?: boolean;
  isPostAuthor: boolean;
}) => {
  const offerStatement = !isCelebratoryPost
    ? isPostAuthor
      ? "Any offers you received will still be available from your "
      : "Any offers that user received will still be available from their "
    : "";
  const description = isPostAuthor
    ? `Your post, including all replies, will be permanently deleted from Braintrust. You can't undo this. ${offerStatement}`
    : `This post, including all replies, will be permanently deleted from Braintrust. You can't undo this. ${offerStatement}`;

  return isCelebratoryPost ? (
    <Typography component="p" mb={3}>
      {description}
    </Typography>
  ) : (
    <Typography component="p" mb={3}>
      {description}
      <Typography
        component="link"
        target="_blank"
        RouterLink={RouterLink}
        to="/talent/dashboard/my_jobs/help_offers/getting_help"
        color="grey-3"
        sx={{
          textDecoration: "underline",
        }}
      >
        My Offers
      </Typography>{" "}
      page
    </Typography>
  );
};
