import { useNavigate } from "react-router-dom";

import {
  StatusForApplicationQuestions,
  StatusForCategory,
  StatusForExperience,
  StatusForJobDescription,
  StatusForProjectDetails,
  StatusForRate,
  StatusForSkills,
  StatusForTitle,
} from "@js/apps/jobs/apps/create-job/components/summarize-module/statuses";
import { ReviewBeforeSendingSummaryBox } from "@js/apps/jobs/components";
import { ModalInstance } from "@js/components/modal";
import type { JobFormValues } from "@js/types/jobs";

import { useCheckJobsLimit } from "../../hooks/check-jobs-limit";

type StatusFieldsForReviewDraftsModalProps = {
  id: number | undefined;
  isLastJobToPublish: boolean;
  values: Partial<JobFormValues>;
};

export const StatusFieldsForReviewDraftsModal = ({
  id,
  isLastJobToPublish,
  values,
}: StatusFieldsForReviewDraftsModalProps) => {
  const navigate = useNavigate();
  const { checkJobsLimit } = useCheckJobsLimit();

  const handleEditCTAClick = () => {
    checkJobsLimit({
      onLimitNotExceeded: () =>
        navigate(
          `/jobs/add_new/${id}/set_up/${isLastJobToPublish ? "" : "?back_to_review_modal=true"}`,
          {},
        ),
    });
    ModalInstance.close();
  };

  const commonProps = {
    values,
    onEditClick: handleEditCTAClick,
  };

  return (
    <ReviewBeforeSendingSummaryBox>
      <StatusForTitle {...commonProps} />
      <StatusForCategory {...commonProps} />
      <StatusForProjectDetails {...commonProps} />
      <StatusForSkills {...commonProps} />
      <StatusForExperience {...commonProps} />
      <StatusForRate {...commonProps} />
      <StatusForJobDescription {...commonProps} />
      <StatusForApplicationQuestions {...commonProps} />
    </ReviewBeforeSendingSummaryBox>
  );
};
