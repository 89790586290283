import {
  Box,
  Button,
  Loader,
  Stack,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { TransferIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ModalInstance } from "@js/components/modal";
import type { ATSIntegration } from "@js/types/employer";
import { pluralize } from "@js/utils";

import { BraintrustLogo } from "./components/braintrust-logo";
import { ATSJobsEmptyState } from "./components/empty-state";
import { IntegrationLogo } from "./components/integration-logo";
import { JobsHeader } from "./components/job-header";
import { JobItem } from "./components/job-item";
import { useATSIntegrationModal } from "./hooks/use-ats-integration-modal";
import { ATSJobsSearch } from "./ats-jobs-search";

import styles from "./style.module.scss";

export const AtsIntegrationModalContent = ({
  integration,
}: {
  integration: ATSIntegration;
}) => {
  const {
    atsJobs,
    filteredAtsJobs,
    handleSelectAllJobs,
    handleToggleSelectJobs,
    handleMoveATSJobsToDraft,
    publishSelectedATSJobs,
    searchValue,
    setSearchValue,
    isLoading,
    someJobsSelected,
    unsyncedJobCount,
    anyJobsSelected,
    selectedJobsCount,
    allJobsSelected,
  } = useATSIntegrationModal({ integration });

  const showData = !!atsJobs?.length && !isLoading;
  const showEmptyState = !atsJobs?.length && !isLoading;

  return (
    <>
      <Box mb="20px" display="flex" alignItems="center" gap="13px">
        <IntegrationLogo
          color={integration.color}
          src={integration.logo_square}
          name={integration.integration}
        />
        <TransferIcon />
        <BraintrustLogo />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems="center"
        gap="20px"
        mb={4}
      >
        <Typography
          component="h1"
          variant="title"
          size="small"
          fontWeight={400}
        >
          Select jobs from {integration.integration}
        </Typography>
        <ATSJobsSearch
          name={integration.integration}
          searchValue={searchValue}
          onSearchValueChange={setSearchValue}
        />
      </Box>

      {showData && (
        <Stack mb={3}>
          <Typography component="h3" size="large" fontWeight={400}>
            {filteredAtsJobs.length} active jobs found ({unsyncedJobCount}{" "}
            unsynced)
          </Typography>
        </Stack>
      )}

      {isLoading && (
        <Box className={styles.jobListLoading}>
          <Loader />
        </Box>
      )}

      {showData && (
        <Box className={styles.jobList}>
          <JobsHeader
            toggleSelectAllJobs={handleToggleSelectJobs}
            someJobsSelected={someJobsSelected}
            allJobsSelected={allJobsSelected}
            jobsCount={atsJobs.length}
          />
          {filteredAtsJobs?.map((job, index) => {
            return (
              <JobItem
                job={job}
                key={job.remote_id}
                index={index}
                disabled={job.already_imported}
                jobsCount={atsJobs?.length}
              />
            );
          })}
        </Box>
      )}

      {showEmptyState && <ATSJobsEmptyState />}

      <Stack justifyContent="flex-end" direction="row" width="100%" gap={2}>
        {anyJobsSelected && (
          <Button
            variant="secondary"
            shape="squared"
            onClick={handleMoveATSJobsToDraft}
          >
            Move to my Drafts
          </Button>
        )}
        <Tooltip
          disabled={!!unsyncedJobCount}
          title="There are no additional jobs available for import."
          placement="top"
          data-testid="select-jobs-disabled-tooltip"
        >
          <span style={{ cursor: "pointer" }}>
            <Button
              disabled={!unsyncedJobCount}
              variant="positive"
              shape="squared"
              onClick={
                anyJobsSelected ? publishSelectedATSJobs : handleSelectAllJobs
              }
            >
              {anyJobsSelected
                ? `Publish ${selectedJobsCount} job${pluralize(selectedJobsCount)} to Braintrust`
                : "Select jobs"}
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </>
  );
};

export const openAtsIntegrationModal = (integration: ATSIntegration) => {
  ModalInstance.open({
    keepOnBackdropClick: true,
    disableEscapeKeyDown: true,
    className: styles.atsIntegrationModal,
    children: <AtsIntegrationModalContent integration={integration} />,
    closeButton: true,
    closeButtonProps: { variant: "tertiary", size: "x-small" },
  });
};
