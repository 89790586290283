import { useMemo } from "react";

import { useGetManagedJobQuery } from "@js/apps/jobs/api";
import { useGetSkillsByIds } from "@js/apps/skills/hooks";
import type { ManagedJob } from "@js/types/jobs";

export const useExistingJobData = (jobId: number | undefined) => {
  const { data: jobData, isFetching: isFetchingJobData } =
    useGetManagedJobQuery({ jobId: jobId as number }, { skip: !jobId });

  const { data: skills, isFetching: isFetchingSkills } = useGetSkillsByIds({
    ids: jobData?.skills,
  });

  const processedJob: ManagedJob | undefined = useMemo(() => {
    if (!jobData || !skills || isFetchingSkills) {
      return;
    }

    const onlyExistingTopSkills = jobData.top_skills.filter((jobTopSkill) =>
      skills.some((skill) => skill.id === jobTopSkill),
    );

    const onlyExistingSkills = jobData.skills.filter((jobSkill) =>
      skills.some((skill) => skill.id === jobSkill),
    );

    return {
      ...jobData,
      top_skills: onlyExistingTopSkills,
      skills: onlyExistingSkills,
    };
  }, [skills, jobData, isFetchingSkills]);

  return {
    jobData: processedJob,
    isFetching: isFetchingJobData || isFetchingSkills,
  };
};
