import { API } from "@js/api";
import type { Skill } from "@js/types/admin";
import type { PaginatedResult } from "@js/types/generic";
import { combineResults } from "@js/utils/data";
import { getUseQueryHookWithDefaultOptions } from "@js/utils/store";

export type GetSkillsQueryParams = {
  ids?: number[];
  ordering?: "name";
  search?: string;
  page?: number;
  page_size?: number;
};

export type GetSkillsResponse = PaginatedResult<Skill>;

const skillsAPI = API.injectEndpoints({
  endpoints: (build) => ({
    getPopularSkills: build.query<Skill[], { roleIds?: number[] } | void>({
      query: (params) => {
        return {
          url: "/popular_skills/",
          method: "GET",
          params: { role: params?.roleIds?.join(",") },
        };
      },
      keepUnusedDataFor: 10000000,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          const skills = res.data;
          dispatch(
            skillsAPI.util.upsertQueryEntries(
              skills.map((skill) => ({
                endpointName: "getSkill",
                arg: { id: skill.id },
                value: skill,
              })),
            ),
          );
        } catch (err) {
          void err;
        }
      },
    }),
    getSkills: build.query<GetSkillsResponse, GetSkillsQueryParams>({
      query: (params) => ({
        url: "/skills/",
        method: "GET",
        params: { ...params, ids: params?.ids?.join(",") },
      }),
      keepUnusedDataFor: 10000000,
      merge: (currentCache, newItems) => {
        const combinedSkillsResults = combineResults(
          currentCache.results,
          newItems.results,
        );

        return {
          ...newItems,
          results: combinedSkillsResults,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        const isFetchingCachedPage =
          !!previousArg?.page &&
          !!currentArg?.page &&
          previousArg?.page >= currentArg?.page;

        if (isFetchingCachedPage) {
          return false;
        }
        // serialized query arg makes it so we are not fetching on page change ...
        // ... force refetch when any ignored param changes, including page (page must be higher)
        const paramsKeys: Array<keyof GetSkillsQueryParams> = ["page"];
        const hasArgChanged = paramsKeys.some(
          (paramKey) => currentArg?.[paramKey] !== previousArg?.[paramKey],
        );

        return hasArgChanged;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const serializedQueryArgIds = queryArgs?.ids
          ? [...queryArgs.ids].sort((a, b) => a - b)
          : undefined;
        const serializeQueryArgs = { ...queryArgs, ids: serializedQueryArgIds };
        delete serializeQueryArgs.page;

        return `${endpointName}(${JSON.stringify(serializeQueryArgs)})`;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          const skills = res.data.results;
          dispatch(
            skillsAPI.util.upsertQueryEntries(
              skills.map((skill) => ({
                endpointName: "getSkill",
                arg: { id: skill.id },
                value: skill,
              })),
            ),
          );
        } catch (err) {
          void err;
        }
      },
    }),
    getSkill: build.query<Skill, { id: number }>({
      query: ({ id }) => ({
        url: `/skills/${id}`,
        method: "GET",
        validateStatus: () => true, // ignore 404 error
      }),
      keepUnusedDataFor: 10000000,
    }),
  }),
});

export const { useLazyGetSkillsQuery } = skillsAPI;

export const useGetSkillsQuery = getUseQueryHookWithDefaultOptions(
  skillsAPI.useGetSkillsQuery,
  { refetchOnMountOrArgChange: false },
);

export const useGetPopularSkillsQuery = getUseQueryHookWithDefaultOptions(
  skillsAPI.useGetPopularSkillsQuery,
  { refetchOnMountOrArgChange: false },
);

export const useGetSkillQuery = getUseQueryHookWithDefaultOptions(
  skillsAPI.useGetSkillQuery,
  { refetchOnMountOrArgChange: false },
);
