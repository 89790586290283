import { useCallback, useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { SortableList } from "@js/components/sortable-list";

import { SelectedSkillItem } from "./item";

import styles from "./style.module.scss";

type SelectedSkillsListBaseProps = {
  error?: string | string[];
  maxFavoriteSkillsCount: number;
  skillsInput: TypedWrappedFieldProps<number[] | null>["input"];
  favoriteSkillsInput: TypedWrappedFieldProps<number[] | null>["input"];
};

export const SelectedSkillsList = ({
  error,
  maxFavoriteSkillsCount,
  skillsInput,
  favoriteSkillsInput,
}: SelectedSkillsListBaseProps) => {
  const skillIds = skillsInput.value;
  const selectedSkillsCount = skillsInput.value?.length ?? 0;

  const getSortedSkillsValue = useCallback(
    (skills: number[], favoriteSkills: number[]) => {
      const sortedSkills = [...skills].sort((a, b) => {
        const isASkillSuperpower = favoriteSkills.includes(a);
        const isBSkillSuperpower = favoriteSkills.includes(b);

        return Number(isBSkillSuperpower) - Number(isASkillSuperpower);
      });

      return sortedSkills;
    },
    [],
  );

  const getNewFavoriteSkillsOnToggle = useCallback(
    (selectedSkillId: number) => {
      const favoriteSkillsValue = favoriteSkillsInput.value || [];
      if (favoriteSkillsValue.includes(selectedSkillId)) {
        return favoriteSkillsValue.filter((skill) => skill !== selectedSkillId);
      }

      return [...favoriteSkillsValue, selectedSkillId];
    },
    [favoriteSkillsInput],
  );

  const handleFavorite = useCallback(
    (selectedSkillId: number) => {
      const newSuperpowerSkills = getNewFavoriteSkillsOnToggle(selectedSkillId);
      favoriteSkillsInput.onChange(newSuperpowerSkills);

      const sortedSkills = getSortedSkillsValue(
        skillsInput.value || [],
        newSuperpowerSkills,
      );

      skillsInput.onChange(sortedSkills);
    },
    [
      getNewFavoriteSkillsOnToggle,
      favoriteSkillsInput,
      getSortedSkillsValue,
      skillsInput,
    ],
  );

  const handleRemove = useCallback(
    (selectedSkillId: number) => {
      const favoriteSkillsValue = favoriteSkillsInput.value || [];
      favoriteSkillsInput.onChange(
        favoriteSkillsValue.filter((skillId) => skillId !== selectedSkillId),
      );
      const skillsValue = skillsInput.value || [];
      skillsInput.onChange(
        skillsValue.filter((skillId) => skillId !== selectedSkillId),
      );
    },
    [skillsInput, favoriteSkillsInput],
  );

  const handleReorder = useCallback(
    (value: Array<{ id: number }>) => {
      const newSkillsValue = value.map((skill) => skill.id);
      const sortedSkills = getSortedSkillsValue(
        newSkillsValue,
        favoriteSkillsInput.value || [],
      );

      skillsInput.onChange(sortedSkills);
    },
    [getSortedSkillsValue, skillsInput, favoriteSkillsInput.value],
  );

  const favoriteSkills = favoriteSkillsInput.value || [];

  const selectedFavoriteQuantity = favoriteSkills?.length ?? 0;
  const isMaxFavoriteSkillsCountReached =
    !!maxFavoriteSkillsCount && favoriteSkills.length >= maxFavoriteSkillsCount;

  const skillIdsSortableListItems = useMemo(() => {
    if (!skillIds) {
      return [];
    }

    return skillIds.map((skillId) => {
      return { id: skillId };
    });
  }, [skillIds]);

  return (
    <div className={styles.list}>
      {error && (
        <Typography component="p" mb={1} color="negative">
          {error}
        </Typography>
      )}
      {selectedSkillsCount > 0 && (
        <Typography
          component="p"
          size="small"
          variant="label"
          ml={5}
          color="grey-2"
        >
          Skills - {selectedSkillsCount}
        </Typography>
      )}
      <SortableList
        items={skillIdsSortableListItems}
        useDragHandle={false}
        reorderAction={handleReorder}
        getUniqueItemKey={(skillItem) => skillItem.id}
        stickToContainerBorders
        isDraggable={(skillItem) =>
          favoriteSkills.includes(skillItem.id) && selectedFavoriteQuantity > 1
        }
      >
        {(skillItem: { id: number }) => (
          <SelectedSkillItem
            key={skillItem.id}
            skillId={skillItem.id}
            isFavorite={favoriteSkills.includes(skillItem.id)}
            onFavorite={handleFavorite}
            onRemove={handleRemove}
            quantityFavoriteSkills={selectedFavoriteQuantity}
            isMaxFavoriteSkillsCountReached={isMaxFavoriteSkillsCountReached}
          />
        )}
      </SortableList>
    </div>
  );
};
