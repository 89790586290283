import type { Skill } from "@js/types/admin";
import { typeGuard } from "@js/utils";

import { useGetSkillQuery } from "../api";

export const useGetSkillById = (
  { id }: { id: number | undefined },
  { skip }: { skip?: boolean } = {},
) => {
  const { data, isLoading, isFetching } = useGetSkillQuery(
    { id: id as number },
    { skip: skip || !id },
  );

  const skillData = getSkillOrUndefinedIfError(data);

  return { data: skillData, isLoading, isFetching };
};

const getSkillOrUndefinedIfError = (
  skillOrError: unknown,
): Skill | undefined => {
  if (!skillOrError || typeof skillOrError !== "object") {
    return;
  }

  if (!typeGuard<unknown, Skill>(skillOrError, "id")) {
    return;
  }

  return skillOrError;
};
