import { useCallback } from "react";

import type { GetSkillsResponse } from "@js/apps/skills/api";
import { useLazyGetSkillsQuery } from "@js/apps/skills/api";

export const useMapSkillNamesToIds = () => {
  const [getSkills] = useLazyGetSkillsQuery();

  const mapSkillNamesToIds = useCallback(
    async (skillNames: string[]): Promise<number[]> => {
      if (!skillNames.length) {
        return [];
      }

      const skillPromises = skillNames.map((skillName) =>
        getSkills({ search: skillName, page: 1, page_size: 1 }, true).unwrap(),
      );

      const results = await Promise.allSettled(skillPromises).then(
        (promiseResults) =>
          promiseResults
            .map((result) =>
              result.status === "fulfilled" ? result.value : null,
            )
            .filter((result): result is GetSkillsResponse => Boolean(result)),
      );

      const skillIds = results
        .map((getSkillsResponse) => getSkillsResponse.results[0]?.id)
        .filter(Boolean);

      return skillIds;
    },
    [getSkills],
  );

  return { mapSkillNamesToIds };
};
