import { isArray } from "underscore";

import {
  getDateMonth,
  getDateYear,
  getMonthName,
  isDateValid,
  subtractFromDate,
} from "@js/utils/date";
import { getTextCount } from "@js/utils/string";
import { isValidUrlRegex } from "@js/utils/url";

export const MAX_YEAR = getDateYear();
export const MIN_YEAR = getDateYear(subtractFromDate(60, "y"));
export const MAX_MONTH = getDateMonth();
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALID_AVATAR_FORMATS =
  "image/png, image/bmp, image/avif, image/gif, image/jpeg, image/apng, image/webp";

export const dateFormat =
  (formatDate = SETTINGS.DATE_FORMAT) =>
  (value?: string) =>
    isDateValid(value, formatDate)
      ? undefined
      : `Date has wrong format. Use this format instead: ${formatDate}`;

// Source: https://stackoverflow.com/a/46181/6160650
export const email = (
  emailAddress: string,
  errorMessage = "Email is not valid.",
) => {
  if (EMAIL_REGEX.test(String(emailAddress).toLowerCase())) {
    // email is ok
    return undefined;
  } else {
    return errorMessage;
  }
};

export const maxLength = (max: number) => (value: string | number) => {
  const val = String(value);
  return val && val.trim().length > max
    ? `Must be ${max} characters or less`
    : undefined;
};

export const minLength = (min: number) => (value?: string | number) => {
  const val = String(value);
  return val && val.trim().length < min
    ? `Must be ${min} characters or more`
    : undefined;
};

export const maxTextLength = (max: number) => (value?: string) => {
  return value && getTextCount(value) > max
    ? `Must be ${max} characters or less`
    : undefined;
};

export const minTextLength = (min: number) => (value?: string) => {
  return value && getTextCount(value) < min
    ? `Must be ${min} characters or more`
    : undefined;
};

export const maxNumberOfValues =
  (max: number, error?: string) => (value: unknown[]) => {
    if (value && isArray(value) && value.length > max) {
      return error || `Please choose max ${max} options.`;
    }
  };

export const minNumberOfValues =
  (min: number, error?: string) => (value: unknown[]) => {
    if (!value || !isArray(value) || value.length < min) {
      return error || `Please choose at least ${min} options.`;
    }
  };

export const maxValue = (max: number, label?: string) => (value?: number) =>
  value && value > max
    ? `The value must be less than or equal to ${label ?? max}`
    : undefined;

export const minValue = (min: number) => (value?: number) =>
  (value || value === 0) && value < min
    ? `The value must be greater than or equal to ${min}`
    : undefined;

export const requiredWithSpecificMessage =
  (specificMessage: string) => (value?: any | any[]) => {
    const isValueEmpty = value === null || value === undefined;
    if (isValueEmpty || (typeof value === "string" && !value.trim()))
      return specificMessage;
    if (Array.isArray(value) && !value.length) return specificMessage;

    return undefined;
  };

export const required = (value: any) => {
  if (
    !value ||
    (typeof value === "string" && !value.trim()) ||
    (Array.isArray(value) && !value.length)
  )
    return "This field is required.";

  return undefined;
};

export const urlValidator =
  (specificError = "The valid URL format is: https://example.com") =>
  (value?: string) => {
    const isValueEmpty = value === null || value === undefined;

    if (isValueEmpty || (typeof value === "string" && !value.trim())) {
      return specificError;
    }

    if (!isValidUrlRegex(value)) return specificError;

    return undefined;
  };

export const noFutureYearValidator = (value: string) => {
  if (!value) return undefined;

  const dateFormatFn = dateFormat("YYYY");
  const maxYearRange = maxValue(MAX_YEAR);
  const minYearRange = minValue(MIN_YEAR);

  const hasValidDateFormat = dateFormatFn(value);
  const hasValidMinYearRange = minYearRange(Number(value));
  const hasValidMaxYearRange = maxYearRange(Number(value));

  return hasValidDateFormat || hasValidMinYearRange || hasValidMaxYearRange;
};

export const noFutureMonthValidator = (
  year: string | number | null,
  month: string | number | null,
) => {
  if (!month || !year) return undefined;

  if (Number(year) !== MAX_YEAR) {
    return undefined;
  }

  if (Number(month) <= MAX_MONTH) {
    return undefined;
  }

  return `Please choose a month before or equal to ${getMonthName(MAX_MONTH)}.`;
};

export const restrictedSigns =
  (
    signs: string[],
    errorMessage: string = `You can't use special signs like: ${signs.join(", ")}`,
  ) =>
  (value?: string) => {
    if (value) {
      for (const sign of signs) {
        if (value.includes(sign)) {
          return errorMessage;
        }
      }
    }

    return undefined;
  };
