import { Box, Loader } from "@hexocean/braintrust-ui-components";
import type { Skill } from "@js/types/admin";

import { SelectablePopularSkills } from "../selectable-popular-skills";

type PopularSkillsProps = {
  isFetchingPopularSkills: boolean;
  filteredSkills?: Pick<Skill, "id" | "name">[];
  toggleSkill: (skillId: number) => void;
};

export const PopularSkills = ({
  isFetchingPopularSkills,
  filteredSkills,
  toggleSkill,
}: PopularSkillsProps) => {
  return (
    <Box
      display="flex"
      gap={1}
      max-height={280}
      width="100%"
      flexWrap="wrap"
      alignContent="flex-start"
    >
      {isFetchingPopularSkills && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Loader />
        </Box>
      )}
      <SelectablePopularSkills
        skills={filteredSkills}
        handleClick={toggleSkill}
      />
    </Box>
  );
};
