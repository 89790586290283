import { useNavigate } from "react-router-dom";

import { clickPublishJobsATS } from "@js/apps/employer/actions";
import { atsIntegrationApiUtil } from "@js/apps/employer/api";
import { useCreateJobMutation } from "@js/apps/jobs/api";
import { jobPublishRequiredDataSchema } from "@js/apps/jobs/apps/create-job/job-publish-required-data-schema";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { ATSJob } from "@js/types/jobs";

import {
  deselectAllJobs,
  setAtsJobsMovedToDraftCount,
  setAtsJobsPublishedCount,
  toggleShowPostImportInfoBar,
} from "../../ats-jobs-slice";
import { useMoveATSJobsToDraft } from "../use-move-ats-jobs-to-draft";

export const usePublishSelectedJobs = (selectedATSJobs: ATSJob[]) => {
  const navigate = useNavigate();
  const { moveATSJobsToDraft } = useMoveATSJobsToDraft();
  const dispatch = useAppDispatch();
  const [createJob] = useCreateJobMutation();

  const publish = async () => {
    const saveToDrafts: ATSJob[] = [];

    try {
      dispatch(clickPublishJobsATS());
      await Promise.allSettled(
        selectedATSJobs.map(async (job) => {
          try {
            // since ATS payload only contain title right now
            // so we check if we have  data required by backend to publish jobs to avoid mulitple pointless requests
            const values = jobPublishRequiredDataSchema.parse(job);
            return await createJob({ ...values, dry_run: false }).unwrap();
          } catch {
            saveToDrafts.push(job);
            throw Error("missing_required_fields");
          }
        }),
      );
      dispatch(
        setAtsJobsPublishedCount(selectedATSJobs.length - saveToDrafts.length),
      );

      if (saveToDrafts.length > 0) {
        await moveATSJobsToDraft(saveToDrafts);
        dispatch(setAtsJobsMovedToDraftCount(saveToDrafts.length));
      }

      dispatch(deselectAllJobs());
    } catch {
      Snackbar.error("Something went wrong.");

      return;
    }

    navigate("/employer/dashboard/my_jobs/");
    dispatch(toggleShowPostImportInfoBar());
    dispatch(atsIntegrationApiUtil.invalidateTags(["ATSSyncedJobs"]));

    ModalInstance.close();
  };

  return { publishSelectedATSJobs: publish };
};
