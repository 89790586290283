import { useMemo } from "react";

import { useGetSkillsQuery } from "@js/apps/skills/api";

type UseGetSkillsByIdsArg = { ids?: number[] };
type UseGetSkillsByIdsOptions = { skip?: boolean };

export const useGetSkillsByIds = (
  { ids }: UseGetSkillsByIdsArg,
  { skip }: UseGetSkillsByIdsOptions = {},
) => {
  const { data, isLoading, isFetching, isError } = useGetSkillsQuery(
    { ids },
    { skip: !ids?.length || skip },
  );

  const sortedSkills = useMemo(() => {
    const skillIds = ids;
    if (!data?.results.length || !skillIds?.length) {
      return [];
    }

    const skillsCopy = [...data.results];
    return skillsCopy
      .filter((skill) => skillIds.includes(skill.id))
      .sort((aSkill, bSkill) => {
        const aSkillIndex = skillIds.indexOf(aSkill.id) ?? 100;
        const bSkillIndex = skillIds.indexOf(bSkill.id) ?? 100;

        return aSkillIndex - bSkillIndex;
      });
  }, [data, ids]);

  return { data: sortedSkills, isLoading, isFetching, isError };
};
