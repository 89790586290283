import { useMemo } from "react";
import type { FormSubmitHandler } from "redux-form";
import { SubmissionError } from "redux-form";
import { skipToken } from "@reduxjs/toolkit/query";

import { useUser } from "@js/apps/common/hooks";
import { skillsSaved } from "@js/apps/freelancer/actions";
import {
  useEditFreelancerSkillsMutation,
  useGetFreelancerPublicProfileQuery,
} from "@js/apps/freelancer/api";
import { useGetSkillsByIds } from "@js/apps/skills/hooks";
import { useAppDispatch } from "@js/hooks";
import type { Role } from "@js/types/roles";
import { typeGuard } from "@js/utils";

export type SkillsFormData = {
  new_skills: number[];
  superpower_skills: number[];
  role: Role | undefined;
};

export const useSkillsForm = () => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const [editFreelancerSkills] = useEditFreelancerSkillsMutation();
  const {
    currentData: freelancerPublicProfile,
    isLoading: isLoadingFreelancerProfile,
  } = useGetFreelancerPublicProfileQuery(user?.freelancer ?? skipToken);

  const freelancerRoles = freelancerPublicProfile?.roles;
  const freelancerSkills = freelancerPublicProfile?.freelancer_skills;

  const freelancerSkillsSkillIds = useMemo(() => {
    if (!freelancerSkills?.length) {
      return [];
    }

    return freelancerSkills.map((freelancerSkill) => freelancerSkill.skill.id);
  }, [freelancerSkills]);

  const { isLoading: isLoadingSkills } = useGetSkillsByIds({
    ids: freelancerSkillsSkillIds,
  });

  const freelancerMainRole = useMemo(
    () =>
      !!freelancerRoles?.length
        ? freelancerRoles.find((role) => role.primary)?.role ||
          freelancerRoles[0].role
        : undefined,
    [freelancerRoles],
  );

  const initialValues: SkillsFormData = useMemo(() => {
    const processedFreelancerSkills = freelancerSkills ?? [];

    const skillsValue = processedFreelancerSkills.map(
      (freelancerSkill) => freelancerSkill.skill.id,
    );

    const superpowerSkillsValue = processedFreelancerSkills
      .filter((freelancerSkill) => freelancerSkill.is_superpower)
      .map((freelancerSkill) => freelancerSkill.skill.id);

    return {
      role: freelancerMainRole,
      new_skills: skillsValue,
      superpower_skills: superpowerSkillsValue,
    };
  }, [freelancerMainRole, freelancerSkills]);

  const onSubmit: FormSubmitHandler<SkillsFormData> = async (
    values,
    _dispatch,
    props,
  ) => {
    const freelancerId = user?.freelancer;
    if (!freelancerId) {
      return;
    }

    const newSkillsPayload = values.new_skills.map((skillId) => {
      const isSuperpowerSkill = values.superpower_skills.includes(skillId);
      return {
        new_skill: skillId,
        is_superpower: isSuperpowerSkill,
      };
    });

    const editFreelancerSkillsArg = {
      id: freelancerId,
      new_skills: newSkillsPayload,
    };

    try {
      const data = await editFreelancerSkills(editFreelancerSkillsArg).unwrap();

      dispatch(
        skillsSaved(
          data.freelancer_skills.map((skill) => {
            return {
              name: skill.skill.name,
              categories: skill.skill.categories,
              is_superpower: skill.is_superpower,
            };
          }),
        ),
      );

      props.reset?.();

      return data;
    } catch (error) {
      const errorToThrow =
        error && typeGuard<unknown, { data: object }>(error, "data")
          ? error.data
          : {};

      throw new SubmissionError(errorToThrow);
    }
  };

  return {
    initialValues,
    onSubmit,
    isLoading: isLoadingSkills || isLoadingFreelancerProfile,
  };
};
