import type { MutableRefObject } from "react";
import type { ConfigProps } from "redux-form";

import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import { Datetime } from "@js/components/date";
import { RouterLink } from "@js/components/link";
import { UserAvatar } from "@js/components/user-avatar";
import type { NoteItem } from "@js/types/admin";
import type { User } from "@js/types/auth";
import { DateTimeFormats } from "@js/utils/date/types";

import type { AddNoteFormData } from "../../forms/add-note";
import { AddNoteForm } from "../../forms/add-note";

import { DeleteNoteModalInstance } from "./hooks/add-delete-notes-handlers";
import { Note } from "./note";

type NotesListContentProps = {
  id: number;
  objectName?: EnumType<typeof ENUMS.NoteContentType>;
  notes: NoteItem[];
  fetchingNotes: boolean;
  onDeleteNote: (
    note: NoteItem,
    targetName?: EnumType<typeof ENUMS.NoteContentType>,
  ) => void;
  highlightedNote: MutableRefObject<HTMLElement | null>;
  isHighlighted?: (noteId: number) => boolean;
  onSubmit: (values: AddNoteFormData) => void;
  onSubmitSuccess: ConfigProps<AddNoteFormData>["onSubmitSuccess"];
  getNoteUrl?: (id: number, noteId: number) => string;
};

export const NotesListContent = ({
  id,
  objectName,
  notes,
  fetchingNotes,
  onSubmit,
  onSubmitSuccess,
  getNoteUrl,
  onDeleteNote,
  highlightedNote,
  isHighlighted,
}: NotesListContentProps) => {
  return (
    <section className="notes-list">
      <AddNoteForm
        initialValues={{ id: id, content: "", objectName: objectName }}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
      />
      {!notes || fetchingNotes ? (
        <Loader />
      ) : (
        <ul className="notes-list__list mt++ mb0">
          {notes.map((note) => {
            const url = !!getNoteUrl ? getNoteUrl(id, note.id) : "";
            const isNoteHighlighted = isHighlighted
              ? isHighlighted(note.id)
              : false;

            return (
              <li key={note.id} className="notes-list__list-item">
                <Note
                  ref={isNoteHighlighted ? highlightedNote : null}
                  avatar={
                    <UserAvatar user={note.user as User} size="md" hideBorder />
                  }
                  content={note.content}
                  name={note.user.public_name}
                  date={
                    <Typography
                      size="small"
                      className="color-gray"
                      component="p"
                    >
                      {url ? (
                        <Typography
                          component="link"
                          color="grey-2"
                          RouterLink={RouterLink}
                          className="notes-list__link"
                          size="small"
                          to={url}
                          title="Note link"
                        >
                          <Datetime
                            date={note.created}
                            format={
                              DateTimeFormats["January 1st 1970, 0:00:00 pm"]
                            }
                          />
                        </Typography>
                      ) : (
                        <Datetime
                          date={note.created}
                          format={
                            DateTimeFormats["January 1st 1970, 0:00:00 pm"]
                          }
                        />
                      )}
                    </Typography>
                  }
                  highlighted={isNoteHighlighted}
                  onDelete={() => onDeleteNote(note, objectName)}
                />
              </li>
            );
          })}
        </ul>
      )}
      <DeleteNoteModalInstance />
    </section>
  );
};
