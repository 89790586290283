import React, { Component } from "react";

import { backendToTime, dateFromNow } from "@js/utils/date";

export class TimeFromNow extends Component<any, any> {
  updateTimeInterval: NodeJS.Timeout | undefined;

  static defaultProps = {
    interval: 10000,
  };

  timeRef: any;

  componentDidMount() {
    this.updateTimeInterval = setInterval(() => {
      this.timeRef.innerHTML = this.time;
    }, this.props.interval);
  }

  componentWillUnmount() {
    clearInterval(this.updateTimeInterval);
  }

  get time() {
    return dateFromNow(this.props.time);
  }

  render() {
    return (
      <span className="time-from-now">
        <abbr
          title={backendToTime(this.props.time).format(
            SETTINGS.DATE_TIME_FORMAT,
          )}
          ref={(ref) => (this.timeRef = ref)}
        >
          {this.time}
        </abbr>
      </span>
    );
  }
}
