import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { usePurchaseUpgradeMutation } from "@js/apps/auth/api";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";

import { BOOST_BTRST_COST } from "../application-onramp-modal/constants";

import { openBoostSuccessModal } from "./boost-success-modal/boost-success-modal";

import styles from "./styles.module.scss";

export type ApplicationBoostModalRightProps = {
  onSuccessPurchaseBoost?: () => void;
};

export const ApplicationBoostModalRight = ({
  onSuccessPurchaseBoost,
}: ApplicationBoostModalRightProps) => {
  const [purchaseUpgrade, { isLoading }] = usePurchaseUpgradeMutation();

  const dispatch = useAppDispatch();

  const clickOnPurchaseBoost = async () => {
    const boost: any = await purchaseUpgrade({
      upgrade_type: "boost",
    }).unwrap();

    if ("error" in boost) {
      return Snackbar.error(
        boost?.error?.data?.error_message || "Error something happened!",
      );
    }
    dispatch(fetchCurrentUser());
    ModalInstance.close();

    if (onSuccessPurchaseBoost) {
      onSuccessPurchaseBoost();
    } else {
      openBoostSuccessModal();
    }
  };

  const topImageSection = () => (
    <Box>
      <img
        className={styles.leftMainContainer}
        src={`${SETTINGS.STATIC_URL}boostmodal/boost-modal-purchase.png`}
        alt="banner"
        width="361"
        height="185"
      />
    </Box>
  );

  const totalEstimateSection = () => (
    <>
      <Box display={"flex"} justifyContent={"space-between"} gap={1}>
        <Typography component="p" size="medium">
          3 Application Boosts
        </Typography>
        <Typography component="p" size="medium">
          39 BTRST
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} gap={1}>
        <Typography component="p" size="medium" fontWeight={500}>
          Total
        </Typography>
        <Typography component="p" size="medium" fontWeight={500}>
          {BOOST_BTRST_COST} BTRST
        </Typography>
      </Box>
    </>
  );

  return (
    <Box display={"flex"} justifyContent={"center"} py={5}>
      <Box maxWidth={"393px"} p={2}>
        {topImageSection()}

        <Box>
          <Typography
            component="h2"
            variant="title"
            size="small"
            fontWeight={400}
            mt={3}
            mb={2}
          >
            Purchase Application Boosts
          </Typography>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <hr />

          <Box display={"flex"} flexDirection={"column"} gap={1}>
            {totalEstimateSection()}
          </Box>

          <hr />

          <Typography component="p" size="small">
            By confirming payment you accept our{" "}
            <span className="link_design">
              {" "}
              <a
                href="https://www.usebraintrust.com/privacy-policy "
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                {" "}
                Privacy Policy
              </a>
            </span>{" "}
            and{" "}
            <span>
              <a
                href="https://www.usebraintrust.com/terms"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                {" "}
                Payments Terms & Conditions
              </a>
            </span>
          </Typography>

          <Box mt={1}>
            <Button
              disabled={isLoading}
              onClick={clickOnPurchaseBoost}
              variant="positive"
              sx={{
                height: "56px",
                borderRadius: "10px !important",
                width: "100%",
              }}
            >
              Purchase Application Boosts
            </Button>
          </Box>
        </Box>
      </Box>{" "}
    </Box>
  );
};
