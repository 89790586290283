import React, { useEffect, useMemo } from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { jobsApi } from "@js/apps/jobs/api";
import { WithJobActionsContext } from "@js/apps/jobs/components/job-actions";
import { useAppDispatch } from "@js/hooks";
import type {
  EmployerOwnJob,
  GetOwnJobsParams,
  JobStatus,
} from "@js/types/jobs";

import type { LoadedSections } from "../../views/dashboard/my-jobs/jobs";
import { DraftItem } from "../draft-item";
import { EmployerMyJobList } from "../my-jobs-list";
import { ReviewDraftsButton } from "../review-import-drafts-button";

import { SimpleAccordion } from "./accordion";
import { useDraftAccordion, useJobAccordion } from "./hooks";

import style from "./style.module.scss";

export const DRAFT_STATUS: GetOwnJobsParams["status"] = "DRAFT_STATUS";

export type JobStatusDisplayed = Exclude<
  JobStatus,
  | "completed_not_hired"
  | "completed_hired"
  | "active_not_filled"
  | "active_filled"
>;

export type JobAccordionProps = {
  status: JobStatusDisplayed;
  headerText: string;
  isLoadingOther: boolean;
  setLoadedSections: React.Dispatch<React.SetStateAction<LoadedSections>>;
  name: string;
  showEmpty?: boolean;
};

export const JobAccordion = ({
  status,
  headerText,
  isLoadingOther,
  setLoadedSections,
  name,
  showEmpty,
}: JobAccordionProps): JSX.Element | null => {
  const { expanded, toggle, loading, results, count } = useJobAccordion({
    status,
    defaultExpanded: showEmpty ? undefined : true,
  });

  useEffect(() => {
    if (count !== undefined)
      setLoadedSections((prev) => ({ ...prev, [status]: true }));
  }, [count, setLoadedSections, status]);

  useEffect(() => {
    if (showEmpty && expanded === undefined && count) {
      toggle({ saveInStore: false });
    }
  }, [showEmpty, toggle, expanded, count]);

  const isEmpty = !Boolean(count);

  if (isLoadingOther || (isEmpty && !showEmpty)) {
    return null;
  }

  return (
    <SimpleAccordion
      name={name}
      expanded={!!expanded}
      loading={loading}
      onClick={toggle}
      header={
        <Typography variant="title" component="h2">
          {headerText} ({count})
        </Typography>
      }
    >
      <JobAccordionList jobs={results} />
    </SimpleAccordion>
  );
};

const JobAccordionList = ({ jobs }: { jobs: EmployerOwnJob[] }) => {
  const dispatch = useAppDispatch();

  const withJobActionsContextProviderValue = useMemo(
    () => ({
      afterAction: () => {
        dispatch(jobsApi.util.invalidateTags([{ type: "ManagedJobs" }]));
      },
    }),
    [dispatch],
  );

  return (
    <WithJobActionsContext.Provider value={withJobActionsContextProviderValue}>
      {!!jobs && <EmployerMyJobList jobs={jobs} />}
    </WithJobActionsContext.Provider>
  );
};

export const DraftAccordion = ({
  headerText,
  isLoadingOther,
  setLoadedSections,
  name,
}: Omit<JobAccordionProps, "status" | "showEmpty" | "defaultExpanded">) => {
  const { expanded, toggle, results, loading, count, showReviewButton } =
    useDraftAccordion({
      status: DRAFT_STATUS,
    });

  useEffect(() => {
    if (count !== undefined)
      setLoadedSections((prev) => ({ ...prev, [DRAFT_STATUS]: true }));
  }, [count, setLoadedSections]);

  useEffect(() => {
    if (expanded === undefined && count) {
      toggle({ saveInStore: false });
    }
  }, [toggle, expanded, count]);

  if (isLoadingOther) {
    return null;
  }

  return (
    <SimpleAccordion
      expanded={!!expanded}
      loading={loading}
      onClick={toggle}
      name={name}
      header={
        <Box id="job-drafts" className={style.draftAccordionHeaderContainer}>
          <Typography variant="title" component="h2">
            {headerText} ({count})
          </Typography>
          {showReviewButton && <ReviewDraftsButton />}
        </Box>
      }
    >
      <Box className="my-job-list" mb={8}>
        {results.length ? (
          results?.map(({ id, data, ats_imported, creator }) => (
            <DraftItem
              id={id}
              key={id}
              title={data.title}
              isAtsImported={ats_imported}
              creator={creator}
            />
          ))
        ) : (
          <span>
            Nothing to display - you haven’t added any job drafts yet.
          </span>
        )}
      </Box>
    </SimpleAccordion>
  );
};
