import { useMemo } from "react";
import { Field } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import {
  saveFilters,
  setSavedFiltersName,
} from "@js/apps/common/components/save-filters/actions";
import { useAccountType } from "@js/apps/common/hooks";
import type { FilterLocation } from "@js/apps/filters/types";
import { jobFilterSaved } from "@js/apps/jobs/actions";
import { useCreateStringDependsOnSelectedFilters } from "@js/apps/jobs/apps/listing/hooks/use-create-string-depends-on-selected-filters";
import { useSkillsData } from "@js/apps/skills/hooks";
import { Snackbar } from "@js/components/snackbar";
import { createFormInstance } from "@js/forms/components";
import { CheckboxField, TextField } from "@js/forms/fields";
import { required } from "@js/forms/validators";
import { useAppDispatch } from "@js/hooks";

import type { JobFilters } from "../../filters";
import { SAVED_FILTERS_ENDPOINT } from "../const";
import { filterSkillsIds, generateSuggestedFilterName } from "../helpers";
import { useSavedJobsFilters } from "../hooks/use-saved-jobs-filters";
import {
  closeSaveFiltersModal,
  SaveFiltersModalInstance,
} from "../save-filters-modal";
import { SaveFiltersModalButtons } from "../save-filters-modal/save-filters-modal-buttons";

type FormData = {
  name: string;
  new_job_notification_enabled?: boolean;
};

type SaveJobsFiltersModalContentProps = {
  filters: Partial<JobFilters>;
  location: FilterLocation;
};

export const openSaveJobsFiltersModal = (
  arg: SaveJobsFiltersModalContentProps,
) => {
  SaveFiltersModalInstance.open({
    children: <SaveJobsFiltersModalContent {...arg} />,
  });
};

const SaveJobsFiltersFormInstance = createFormInstance<FormData, unknown>(
  "save-jobs-filters-form",
);

const SaveJobsFiltersModalContent = ({
  filters,
  location,
}: SaveJobsFiltersModalContentProps) => {
  const { savedJobFilters } = useSavedJobsFilters();
  const { isEmployer } = useAccountType();

  const dispatch = useAppDispatch();

  const initialName = useMemo(
    () => generateSuggestedFilterName("Job", savedJobFilters),
    [savedJobFilters],
  );

  const ids = filterSkillsIds(filters);
  const { skillsData } = useSkillsData(ids);

  const filtersString = useCreateStringDependsOnSelectedFilters(
    filters,
    location,
    skillsData,
  );

  return (
    <SaveJobsFiltersFormInstance
      onSubmit={async ({ name, new_job_notification_enabled }: FormData) => {
        const payload = { filters, name, new_job_notification_enabled };

        await dispatch(saveFilters(payload, SAVED_FILTERS_ENDPOINT.JOBS));

        closeSaveFiltersModal();

        dispatch(
          setSavedFiltersName({
            location,
            name,
          }),
        );

        Snackbar.success(`Filters saved as "${name}".`);
      }}
      onSubmitSuccess={() => {
        dispatch(jobFilterSaved());
      }}
      initialValues={{
        name: initialName,
        new_job_notification_enabled: true,
      }}
    >
      <Typography component="h1" variant="title" size="medium" mb={1} mt={5.5}>
        Save this job search
      </Typography>
      <Typography component="p" variant="paragraph" size="large" mb={1}>
        {filtersString}
      </Typography>
      <Field
        id="job-filters-name"
        name="name"
        label="Name"
        size="small"
        placeholder="Give your search a name"
        component={TextField}
        validate={[required]}
      />
      {!isEmployer && (
        <Box sx={{ mt: 1 }}>
          <Field
            id="new_job_notification_enabled"
            name="new_job_notification_enabled"
            label="Email me when new jobs match my search"
            component={CheckboxField}
          />
        </Box>
      )}
      <SaveFiltersModalButtons />
    </SaveJobsFiltersFormInstance>
  );
};
