import React, { useState } from "react";
import { Field } from "redux-form";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { ReceiptDetails } from "@js/apps/common/components/price-details";
import { OTPAuthCodeField } from "@js/apps/common/forms/fields/otp/otp-auth-code";
import { createOTPModalInstance } from "@js/apps/common/forms/fields/otp/otp-auth-modal";
import { useHas2FaEnabled } from "@js/apps/common/hooks";
import type { ActivityHelpOfferUser } from "@js/apps/dashboard/types";
import { openOTPDisabledWarningModal } from "@js/apps/settings/components/otp-auth/otp-disabled-warning";
import { RouterLink } from "@js/components/link";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import { CheckboxField } from "@js/forms/fields";
import type { HelpOffer } from "@js/types/give-and-get-help";

import { OfferVisual } from "../../offer-visual";

const ACCEPT_HELP_OFFER_FORM = "accept-offer-confirm-form";

export const { openAcceptOfferOTPModal, closeAcceptOfferOTPModal } =
  createOTPModalInstance("acceptOfferOTPModal");

export type AcceptOfferConfirmFormValues = {
  code?: string;
  is_backup_code?: boolean;
};

const AcceptOfferFormConfirmInstance = createFormInstance<
  AcceptOfferConfirmFormValues,
  unknown
>(ACCEPT_HELP_OFFER_FORM);

type AcceptOfferConfirmFormProps = {
  helper: ActivityHelpOfferUser;
  asker: ActivityHelpOfferUser;
  onSubmit: (values: AcceptOfferConfirmFormValues) => void;
  category: string;
  isFromOnramp?: boolean;
  offer: HelpOffer;
};

const AcceptOfferConfirmForm = ({
  helper,
  asker,
  onSubmit,
  category,
  isFromOnramp,
  offer,
}: AcceptOfferConfirmFormProps) => {
  const parsedBudget = isNaN(+offer.budget) ? 0 : +offer.budget;
  return (
    <AcceptOfferFormConfirmInstance onSubmit={onSubmit}>
      {parsedBudget ? (
        <AcceptOfferConfirmWithBudgetContent
          asker={asker}
          helper={helper}
          offer={offer}
          isFromOnramp={isFromOnramp}
        />
      ) : (
        <AcceptOfferConfirmNoBudgetContent
          asker={asker}
          helper={helper}
          category={category}
        />
      )}
    </AcceptOfferFormConfirmInstance>
  );
};

const AcceptOfferConfirmWithBudgetContent = ({
  asker,
  helper,
  isFromOnramp,
  offer,
}: {
  asker: ActivityHelpOfferUser;
  helper: ActivityHelpOfferUser;
  isFromOnramp?: boolean;
  offer: HelpOffer;
}) => {
  const isMobile = useMediaQuery("sm");
  const [openAuth, setOpenAuth] = useState<boolean>(false);

  const has2FaEnabled = useHas2FaEnabled();

  return (
    <div className="accept-offer-confirm-modal">
      {openAuth && has2FaEnabled ? (
        <AcceptOfferConfirmOTPAuch
          asker={asker}
          helper={helper}
          onCancel={setOpenAuth}
        />
      ) : (
        <>
          <div className="accept-offer-confirm-modal__top-section">
            <Typography
              component="label"
              variant="label"
              size="large"
              fontWeight={500}
            >
              {isFromOnramp ? "BTRST purchase complete" : "Offer payment"}
            </Typography>

            <OfferVisual exchangeFrom={asker} exchangeTo={helper} />

            <div>
              <Typography
                component="h1"
                variant={isMobile ? "paragraph" : "title"}
                size={isMobile ? "large" : "small"}
                fontWeight={400}
              >
                {isFromOnramp
                  ? `${offer.budget} BTRST is now available in your wallet.`
                  : `Send ${offer.budget} BTRST to ${helper.first_name} to accept the offer`}
              </Typography>
              <Typography
                component="p"
                variant="paragraph"
                size={isMobile ? "medium" : "large"}
                mt={0.5}
              >
                {isFromOnramp
                  ? `You can send ${offer.budget} BTRST to ${helper.first_name} to accept their 1:1 help offer. Your money will be held safe until you have received the help to your satisfaction.`
                  : `Start immediately after paying. Your money will be held safe until you have received the help to your satisfaction`}
              </Typography>
            </div>
          </div>
          <div className="accept-offer-confirm-modal__receipt">
            <ReceiptDetails offer={offer} />
          </div>

          <div className="accept-offer-confirm-modal__otp-disclaimer">
            <Typography component="p" size="small" variant="paragraph">
              You’ll need 2-Step Authentication to pay {helper.first_name}, so
              have your phone ready
            </Typography>
          </div>

          <div className="accept-offer-confirm-modal__bottom-section">
            <Typography component="p" variant="paragraph" size="medium">
              By confirming payment you accept our{" "}
              <Typography
                component="a"
                href={SETTINGS.PRIVACY_DOC_URL}
                target="_blank"
                className="policy-field-link"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
                RouterLink={RouterLink}
              >
                Privacy Policy
              </Typography>
              ,{" "}
              <Typography
                component="a"
                href={SETTINGS.PROFESSIONAL_NETWORK_TERMS_DOC_URL}
                target="_blank"
                className="policy-field-link"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
                RouterLink={RouterLink}
              >
                Refund Policy
              </Typography>{" "}
              and{" "}
              <Typography
                component="a"
                href={SETTINGS.TERMS_DOC_URL}
                target="_blank"
                className="policy-field-link"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
                RouterLink={RouterLink}
              >
                Payments Terms & Conditions
              </Typography>
            </Typography>
            <Button
              variant="positive-2"
              shape="squared"
              type="submit"
              onClick={(event: React.SyntheticEvent) => {
                if (!has2FaEnabled) {
                  event.preventDefault();
                  openOTPDisabledWarningModal();
                }
                setOpenAuth(true);
              }}
            >
              Send
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

type AcceptOfferConfirmOTPAuchProps = {
  onCancel: (openAuth: boolean) => void;
  asker: ActivityHelpOfferUser;
  helper: ActivityHelpOfferUser;
};

export const AcceptOfferConfirmOTPAuch = ({
  onCancel,
  asker,
  helper,
}: AcceptOfferConfirmOTPAuchProps) => {
  return (
    <>
      <div className="accept-offer-confirm-modal__top-section">
        <Typography
          component="label"
          variant="label"
          size="large"
          fontWeight={500}
        >
          Offer payment
        </Typography>

        <OfferVisual exchangeFrom={asker} exchangeTo={helper} />
      </div>
      <div className="accept-offer-confirm-modal__authentication">
        <div id="otp-description">
          <Typography component="h4" variant="title" fontWeight={400} mb={2}>
            2-step authentication
          </Typography>
          <Typography component="p" mb={4}>
            Enter the 6-digit code generated by your authenticator app or one of
            the backup codes.
          </Typography>
        </div>
        <ModalConfirm
          cancelText="Back"
          onCancel={() => onCancel(false)}
          buttonsOnEnds
        >
          <OTPAuthCodeField
            inputProps={{
              "aria-describedby": "otp-description",
              autoFocus: true,
            }}
          />
          <Field
            name="is_backup_code"
            component={CheckboxField}
            label="Use backup code instead"
          />
        </ModalConfirm>
      </div>
    </>
  );
};

const AcceptOfferConfirmNoBudgetContent = ({
  asker,
  helper,
  category,
}: {
  asker: ActivityHelpOfferUser;
  helper: ActivityHelpOfferUser;
  category: string;
}) => {
  return (
    <div className="accept-offer-confirm-modal">
      <div className="accept-offer-confirm-modal__top-section">
        <Typography
          component="label"
          variant="label"
          size="large"
          fontWeight={500}
        >
          Accept offer
        </Typography>

        <OfferVisual exchangeFrom={asker} exchangeTo={helper} />

        <div>
          <Typography
            component="h1"
            variant="title"
            size="small"
            fontWeight={400}
          >
            Accept {helper.first_name}'s offer on {category}
          </Typography>
          <Typography component="p" variant="paragraph" size="large">
            Start immediately after accepting this offer.
          </Typography>
        </div>
      </div>

      <div className="accept-offer-confirm-modal__bottom-section">
        <Typography component="p" variant="paragraph" size="medium">
          By confirming you accept our{" "}
          <Typography
            component="a"
            href={SETTINGS.PRIVACY_DOC_URL}
            target="_blank"
            className="policy-field-link"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
            RouterLink={RouterLink}
          >
            Privacy Policy
          </Typography>{" "}
          and{" "}
          <Typography
            component="a"
            href={SETTINGS.TERMS_DOC_URL}
            target="_blank"
            className="policy-field-link"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
            RouterLink={RouterLink}
          >
            Payments Terms & Conditions
          </Typography>
        </Typography>
        <Button variant="positive-2" shape="squared" type="submit">
          Accept
        </Button>
      </div>
    </div>
  );
};

type OpenAcceptOfferConfirmFormConfig = {
  helper: ActivityHelpOfferUser;
  asker: ActivityHelpOfferUser;
  category: string;
  onSubmit: (values: AcceptOfferConfirmFormValues) => Promise<void>;
  isFromOnramp?: boolean;
  offer: HelpOffer;
};

export const openAcceptOfferConfirmForm = ({
  helper,
  asker,
  category,
  onSubmit,
  isFromOnramp,
  offer,
}: OpenAcceptOfferConfirmFormConfig): void => {
  ModalInstance.open({
    closeButton: true,
    keepOnBackdropClick: true,
    closeButtonProps: { variant: "tertiary", size: "x-small" },
    className: "offer-payment-modal-width",
    children: (
      <AcceptOfferConfirmForm
        helper={helper}
        asker={asker}
        offer={offer}
        onSubmit={onSubmit}
        category={category}
        isFromOnramp={isFromOnramp}
      />
    ),
  });
};
