import React from "react";
import cs from "classnames";

import { assertUnreachable } from "@js/utils/typescript";

import { RouterLink } from "../link";

type LogoProps = {
  variant?: "full" | "full-white" | "full-black" | "symbol" | "symbol-white";
  size?: number;
  className?: string;
  href?: string;
  alt?: string;
  noLink?: boolean;
};

export const Logo = ({
  size,
  className,
  variant,
  href,
  alt = "Braintrust Token",
  noLink,
}: LogoProps): JSX.Element => {
  let _fileName = "logo/logo.svg";
  let _width = 142; // default
  let _className = className;

  switch (variant) {
    case "full-white":
      _fileName = "logo/logo-white.svg";
      break;
    case "full-black":
      _fileName = "logo/logo-white.svg";
      _className = _className + " logo--black";
      break;
    case "symbol":
      _fileName = "logo/logo-symbol.svg";
      _width = 45;
      break;
    case "symbol-white":
      _fileName = "logo/logo-symbol-white.svg";
      _width = 45;
      break;
    case "full":
    case undefined:
      break;
    default: {
      assertUnreachable(variant);
    }
  }

  const imgWidth = size || _width;
  const imgHeight =
    variant === "symbol" || variant === "symbol-white"
      ? imgWidth
      : imgWidth / 4.55;

  const img = (
    <img
      src={`${SETTINGS.STATIC_URL}${_fileName}`}
      alt={alt}
      width={imgWidth}
      height={imgHeight}
    />
  );

  if (noLink) {
    return <span className={cs("logo", _className)}>{img}</span>;
  }

  if (href) {
    return (
      <a href={href} className={cs("logo", _className)}>
        {img}
      </a>
    );
  }

  return (
    <RouterLink to="/" className={cs("logo", _className)}>
      {img}
    </RouterLink>
  );
};
