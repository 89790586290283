import type { SimpleSkill } from "../types";

export const filterOutSelectedSkills = (args: {
  skills: SimpleSkill[];
  selectedSkills: number[];
}) => {
  const { skills, selectedSkills } = args;

  return skills?.filter((skill) => !selectedSkills?.includes(skill.id));
};
