export const isTalentPathname = (pathname: string): boolean =>
  /^\/talent\/[0-9]\d*/.test(pathname);

const profileCompletionTopBarHeight = 34;
export const getProfileCompletionTopBarVisiblePartHeight = ({
  showCompleteProfileTopBar,
  windowScrollTop,
}: {
  windowScrollTop: number;
  showCompleteProfileTopBar: boolean;
}): number => {
  if (!showCompleteProfileTopBar) {
    return 0;
  }

  const profileCompletionTopBarVisiblePartHeight =
    profileCompletionTopBarHeight - windowScrollTop;
  if (profileCompletionTopBarVisiblePartHeight <= 0) {
    return 0;
  }

  return profileCompletionTopBarVisiblePartHeight;
};
