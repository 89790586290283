import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";
import cs from "classnames";

import {
  Box,
  Button,
  IconButton,
  ProgressBarStepper,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { LongArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { PROF_NET_ONBOARDING_PATHS } from "@js/apps/onboarding/constants";
import { useNewClientSignUpInvitation } from "@js/apps/onboarding/hooks/new-client-signup-invitation";
import { useNewClientSignUpSelfServe } from "@js/apps/onboarding/hooks/new-client-signup-self-serve";
import { RouterLink } from "@js/components/link";
import { CoreLayout } from "@js/layouts/core";
import { LocalStorage } from "@js/services";

import { OnboardingProfNetHeader } from "./onboarding-profnet-header";

const StepError = ({ meta }: TypedWrappedFieldProps) => {
  if (!meta.error) return null;

  return (
    <Typography
      data-testid="onboarding-step-error"
      error
      mr={2}
      variant="paragraph"
      component="span"
      size="medium"
    >
      {meta.error}
    </Typography>
  );
};

const CreateTalentAccountCta = () => {
  const isClientSelfServe = useNewClientSignUpSelfServe();

  if (!isClientSelfServe) {
    return null;
  }

  const removeJoiningReasons = () => {
    const signUpData = JSON.parse(
      LocalStorage.getItem(LocalStorage.keys.ONBOARDING_SAVED_DATA) ?? "{}",
    );

    delete signUpData["joining_reasons"];

    LocalStorage.setItem(
      LocalStorage.keys.ONBOARDING_SAVED_DATA,
      JSON.stringify(signUpData),
    );
  };

  return (
    <Typography variant="paragraph" component="p" size="small" mb={2}>
      Looking for work?{" "}
      <Typography
        component="link"
        size="small"
        fontWeight={500}
        RouterLink={RouterLink}
        to={PROF_NET_ONBOARDING_PATHS.GOALS_PAGE.path}
        onClick={removeJoiningReasons}
      >
        Create a Talent account
      </Typography>
    </Typography>
  );
};

type OnboardingProftNetLayoutTypes = {
  onBack?: () => void;
  onContinue?: () => void;
  activeStep?: number;
  pageTitle?: string;
  buttonText?: string;
  isEmailConfirmPage?: boolean;
  isChoosingGoalsAndInterests?: boolean;
};

export const OnboardingProfNetLayout: React.FC<
  React.PropsWithChildren<OnboardingProftNetLayoutTypes>
> = ({
  children,
  onBack,
  onContinue,
  activeStep,
  buttonText,
  isEmailConfirmPage,
  pageTitle = "Hi! Tell us why you’re here.",
  isChoosingGoalsAndInterests = false,
}) => {
  const { isClientInvitation, isClientCompanyNodePresent } =
    useNewClientSignUpInvitation();
  const isClientSelfServe = useNewClientSignUpSelfServe();

  const onboardingStepsQuantity = 3;
  const isActiveStep = typeof activeStep === "number";
  const isTablet = useMediaQuery("md");

  const referral =
    LocalStorage.getItem(LocalStorage.keys.SIGN_UP_REFERRER) ?? undefined;

  const showProgressBarStepper =
    !isClientInvitation &&
    !isClientSelfServe &&
    !isClientCompanyNodePresent &&
    isActiveStep;

  return (
    <div
      className={cs({
        "onboarding-layout-left": !isEmailConfirmPage,
        "email-confirm-page-wrapper": isEmailConfirmPage,
      })}
    >
      <CoreLayout
        className={cs("proft-net-onboarding-layout", {
          "proft-net-onboarding-layout--narrow": isActiveStep,
          "proft-net-onboarding-layout--wide": !isActiveStep,
          "proft-net-onboarding-layout--center": isEmailConfirmPage,
        })}
        pageTitle={pageTitle}
      >
        {!isEmailConfirmPage && <OnboardingProfNetHeader referral={referral} />}
        <Box
          className={cs("proft-net-onboarding-layout__content", {
            "proft-net-onboarding-layout__content--verify-email":
              isEmailConfirmPage,
          })}
        >
          {children}
        </Box>

        {isTablet && isChoosingGoalsAndInterests && (
          <Box className="onboarding-layout__error">
            <Field name="onboarding_step_error" component={StepError} />
          </Box>
        )}

        <NavigationButtons
          onBack={onBack}
          onContinue={onContinue}
          buttonText={buttonText}
          isChoosingGoalsAndInterests
        />

        {Number(activeStep) >= 0 && (
          <Typography component="p" mt={5} mb={2} size="small">
            Have an account?{" "}
            <Typography
              component="link"
              to="/auth/login/"
              size="small"
              fontWeight={500}
              RouterLink={RouterLink}
            >
              Log in
            </Typography>
          </Typography>
        )}

        <CreateTalentAccountCta />

        {showProgressBarStepper && (
          <Box className="onboarding-layout__stepper">
            <ProgressBarStepper
              activeStep={activeStep}
              stepsLength={onboardingStepsQuantity}
            />
          </Box>
        )}
      </CoreLayout>
    </div>
  );
};

type NavigationButtonsProps = Pick<
  OnboardingProftNetLayoutTypes,
  "onBack" | "onContinue" | "isChoosingGoalsAndInterests" | "buttonText"
>;

const NavigationButtons = ({
  onBack,
  onContinue,
  buttonText,
  isChoosingGoalsAndInterests,
}: NavigationButtonsProps) => {
  const isTablet = useMediaQuery("md");

  const shouldRender = Boolean(onBack || onContinue);

  if (!shouldRender) return null;

  return (
    <Box
      className="onboarding-layout__navigation-buttons"
      justifyContent={!!onBack ? "space-between" : "flex-end"}
    >
      {!!onBack && (
        <IconButton variant="transparent" onClick={onBack} aria-label="Go back">
          <LongArrowLeftIcon />
        </IconButton>
      )}

      {isChoosingGoalsAndInterests && !isTablet && (
        <Field name="onboarding_step_error" component={StepError} />
      )}

      <Box>
        {!!onContinue && (
          <Button
            variant="primary"
            size="medium"
            type="submit"
            onClick={() => {
              onContinue();
            }}
          >
            {buttonText || "Continue"}
          </Button>
        )}
      </Box>
    </Box>
  );
};
