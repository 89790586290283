import { useCallback } from "react";

import {
  IconButton,
  Menu,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  FlagIcon,
  MoreVertIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { ReportPayload } from "@js/apps/give-and-get-help/hooks";
import {
  useLazyGetTalentReviewsQuery,
  useReportReviewMutation,
} from "@js/apps/reviews/api";
import { DEFAULT_REVIEWS_COUNT } from "@js/apps/reviews/constants";
import { Snackbar } from "@js/components/snackbar";

import { openReportReviewModalInstance } from "../review-card-modal-content";

type ReviewCardActionMenuProps = {
  id: number;
  isOwnProfile: boolean;
  profileId: number;
};

export const ReviewCardActionMenu = ({
  id,
  isOwnProfile,
  profileId,
}: ReviewCardActionMenuProps) => {
  const isMobile = useMediaQuery("md");
  const [reportReview] = useReportReviewMutation();
  const [triggerGetTalentReviews] = useLazyGetTalentReviewsQuery();

  const handleReportReview = useCallback(
    (reportReason: ReportPayload) => {
      reportReview({ id, data: reportReason })
        .unwrap()
        .then(() => {
          triggerGetTalentReviews(
            {
              id: profileId,
              count: DEFAULT_REVIEWS_COUNT,
            },
            false,
          );
          Snackbar.success("Review reported.");
        })
        .catch(() => Snackbar.error("Failed to report the review."));
    },
    [id, reportReview, profileId, triggerGetTalentReviews],
  );

  if (!isOwnProfile) {
    return null;
  }

  return (
    <Menu
      drawerOnMobile={isMobile}
      anchor={
        <IconButton
          style={{ padding: 0 }}
          aria-label="profile-review-menu"
          aria-haspopup="true"
          variant="transparent"
          size="medium"
        >
          <MoreVertIcon style={{ color: "inherit" }} />
        </IconButton>
      }
    >
      <Menu.Item
        onClick={() => openReportReviewModalInstance(handleReportReview)}
      >
        <FlagIcon sx={{ color: "var(--negative-1)" }} />
        <Typography component="span" color="negative" ml={1}>
          Report review
        </Typography>
      </Menu.Item>
    </Menu>
  );
};
