import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  IconButton,
  Pagination,
  PaginationWrapper,
  SwipeableDrawer,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { InviteTalentCardItem } from "@js/apps/jobs/components/invite-talent-to-bid-card";
import { FindMoreTalentToInviteCTA } from "@js/apps/jobs/components/job-management-requirements-cta";
import { RouterLink } from "@js/components/link";

import { useGetTalentInviteSuggestionsWithSmartMatching } from "../../hooks/get-talent-invite-suggestions-with-smart-matching";
import { useInvitationNotificationManager } from "../../hooks/invitation-notification-manager";
import { InvitesLoadingStateTalentsWithSmartMatching } from "../loading-state-talents-with-smart-matching";

export const InviteTalentDrawer = () => {
  const {
    talentInviteSuggestions,
    inviteSuggestionsCount,
    isSmartMatchingInProgress,
    job,
    analyzingProfilesCount,
    jobId,
    page,
  } = useGetTalentInviteSuggestionsWithSmartMatching();

  const { handleInviteNotification } = useInvitationNotificationManager();

  const onOpen = () => null;

  const _isOpen = true;
  const navigate = useNavigate();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (page && wrapperRef.current) {
      wrapperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [page]);

  if (!jobId || !job || !analyzingProfilesCount) return null;

  return (
    <>
      <SwipeableDrawer
        open={_isOpen}
        onClose={() => navigate(`/jobs/${jobId}`)}
        onOpen={onOpen}
        anchor="right"
        ref={wrapperRef}
      >
        <Box
          sx={{
            width: { xs: "auto", md: "90vw", lg: "72vw" },
            p: 3,
            maxWidth: "1040px",
          }}
        >
          <Header />

          <Typography
            sx={{ mt: 5 }}
            component="h4"
            variant="title"
            fontWeight={400}
            size="medium"
          >
            Invite matched Talent to apply
          </Typography>

          <Box mt={3}>
            {isSmartMatchingInProgress ? (
              <InvitesLoadingStateTalentsWithSmartMatching
                analyzingProfilesCount={analyzingProfilesCount}
              />
            ) : (
              talentInviteSuggestions?.length &&
              talentInviteSuggestions.map((talentInviteSuggestion) => {
                return (
                  <InviteTalentCardItem
                    key={talentInviteSuggestion.id}
                    freelancer={talentInviteSuggestion}
                    job={job}
                    notificationHandler={handleInviteNotification}
                  />
                );
              })
            )}
          </Box>
          {talentInviteSuggestions && (
            <PaginationWrapper>
              <Pagination
                page={Number(page)}
                RouterLink={RouterLink}
                perPage={SETTINGS.TALENT_INVITE_SUGGESTION_PER_PAGE}
                count={inviteSuggestionsCount}
              />
            </PaginationWrapper>
          )}
          <Box mt={6}>
            {!isSmartMatchingInProgress && (
              <FindMoreTalentToInviteCTA job={job} />
            )}
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

const Header = () => {
  const { id } = useParams();
  return (
    <Box display="flex" mx={-0.5} justifyContent="flex-end">
      <IconButton
        variant="transparent"
        href={`/jobs/${id}`}
        RouterLink={RouterLink}
        aria-label="Close"
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};
