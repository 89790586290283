import { useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useUser } from "@js/apps/common/hooks";
import { openEmailConfirmationModal } from "@js/apps/onboarding/components/sign-up-confirmation-content";

type UseEmailConfirmationModalProps = {
  pathToShowConfirmationModal?: RegExp;
  pathsToHideConfirmationModal?: Array<RegExp>;
};

export const useEmailConfirmationModal = ({
  pathToShowConfirmationModal,
  pathsToHideConfirmationModal,
}: UseEmailConfirmationModalProps) => {
  const user = useUser();
  const wasShownRef = useRef(false);
  const { pathname } = useLocation();
  const isSessionImpersonated = !!user?.is_impersonated_session;
  const isVerifiedUser = !!user?.is_verified;

  const matchPath = useCallback(
    (path: RegExp) => path.test(pathname),
    [pathname],
  );

  useEffect(() => {
    if (isVerifiedUser || wasShownRef.current || isSessionImpersonated) return;

    if (
      pathToShowConfirmationModal &&
      !pathToShowConfirmationModal.test(pathname)
    ) {
      return;
    }

    if (
      pathsToHideConfirmationModal &&
      pathsToHideConfirmationModal.some(matchPath)
    ) {
      return;
    }

    wasShownRef.current = true;
    openEmailConfirmationModal();
  }, [
    isVerifiedUser,
    pathname,
    wasShownRef,
    pathToShowConfirmationModal,
    pathsToHideConfirmationModal,
    isSessionImpersonated,
    matchPath,
  ]);
};
