import type { RefObject } from "react";
import React from "react";
import type { FieldArrayFieldsProps } from "redux-form";
import type { DragEndEvent, Over, UniqueIdentifier } from "@dnd-kit/core";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import _ from "underscore";

import {
  DragIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
} from "@hexocean/braintrust-ui-components/Icons";

/*

Library documentation: https://github.com/clauderic/dnd-kit

-----------------------------------------------------

ListItem shouldn't have margin. Apply ElementProps={{className: 'some-class'}} or use padding in ListItem instead.

-----------------------------------------------------

Example usage:
- with redux-form fields: frontend/js/apps/freelancer/forms/fields/skill-types.js

Action to dispatch:

export const reorderWorkSample = (reorderedItems) => new Promise((resolve, reject) => {
    dispatch({
        type: REORDER_WORK_SAMPLE,
        payload: reorderedItems,
    });

    const itemPositions = reorderedItems.map((item, index) => ({
        id: item.id,
        order: index,
    }));

    return axios
        .post('/api/manage_portfolio_items/update_positions/', { item_positions: itemPositions })
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
});

Component:

const ProjectsList = ({ projects }: ProjectsListProps) => {
    return <SortableList items={projects} reorderAction={reorderWorkSample}>
        {(item, index) => { // index is optional - depends on ListItem API
            return <ProjectsListItem index={index} projectsItem={item} />;
        }}
    </SortableList>;
};

-----------------------------------------------------
If ElementProps render function is passed it has to get the draggable props to attach proper listeners from dnd-kit
const ProjectsList = ({ projects }: ProjectsListProps) => {
    return <SortableList items={projects} reorderAction={reorderWorkSample}
        ElementProps={{
            render: ({ children, ...draggableProps }) => <li
                {...draggableProps}>
                {children}
            </li>
        }}
    >
        {(item, index) => { // index is optional - depends on ListItem API
            return <ProjectsListItem index={index} projectsItem={item} />;
        }}
    </SortableList>;
*/

type SortableListProps<T extends { id: UniqueIdentifier }> = {
  children: (...props: any) => JSX.Element | null;
  className?: string;
  items: T[];
  renderInstructions?: boolean;
  ElementProps?: {
    render?: (props: any) => JSX.Element;
  };
  reorderAction: (items: T[], oldIndex: number, newIndex: number) => void;
  ContainerProps?: Record<string, any>;
  draggingConfig?: Record<string, any>;
  getUniqueItemKey: (item: T) => string | number;
  dragHandleStyle?: Record<string, string>;
  useDragHandle?: boolean;
  stickToContainerBorders?: boolean;
  isDraggable?: (item?: any) => boolean;
};

export const SortableList = <T extends { id: UniqueIdentifier }>({
  children,
  className = "",
  items,
  renderInstructions = false,
  ElementProps = {},
  reorderAction,
  ContainerProps = {},
  draggingConfig,
  getUniqueItemKey,
  dragHandleStyle = {},
  useDragHandle = true,
  stickToContainerBorders = false,
  isDraggable = () => true,
}: SortableListProps<T>) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: draggingConfig?.activationConstraint || {
      distance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: draggingConfig?.activationConstraint || {
      distance: 10,
    },
  });

  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  if (!(isFieldArray(items) || (_.isArray(items) && items.length))) {
    return null;
  }

  const handleSortEnd: OnSortEndHandler = ({ oldIndex, newIndex }) => {
    const reorderedItems = arrayMove(items, oldIndex, newIndex);

    reorderAction(reorderedItems, oldIndex, newIndex);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.map(getUniqueItemKey).indexOf(active.id);
      // Over has type Over | null which cause issue although UniqueIdentifier can't be null
      const newIndex = items.map(getUniqueItemKey).indexOf((over as Over)?.id);

      handleSortEnd({ oldIndex, newIndex });
    }

    document.body.style.cursor = "default";
    document.body.classList.remove("no-select");
  };

  const handleDragStart = () => {
    document.body.style.cursor = "move";
    document.body.classList.add("no-select");
  };

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      modifiers={
        stickToContainerBorders
          ? [restrictToParentElement, restrictToVerticalAxis]
          : []
      }
      sensors={sensors}
      collisionDetection={closestCenter}
    >
      <SortableContext items={items}>
        <SortableContainer className={className} {...ContainerProps}>
          {items.map((item, index) => {
            return (
              <SortableItem
                key={getUniqueItemKey(item)}
                id={getUniqueItemKey(item)}
                dragHandleStyle={dragHandleStyle}
                useDragHandle={useDragHandle}
                onSortEnd={handleSortEnd}
                isDraggable={isDraggable(item)}
                {...ElementProps}
              >
                {children(item, index)}
              </SortableItem>
            );
          })}
          {renderInstructions && (
            <span id={SortableList.INSTRUCTIONS_ID} className="sr-only">
              Press space to pick up item. Use arrows to move item. Press space
              again to drops the item in its new position.
            </span>
          )}
        </SortableContainer>
      </SortableContext>
    </DndContext>
  );
};

SortableList.INSTRUCTIONS_ID = "sortable-list-instructions";

type ContainerProps = Record<string, any> & {
  children?: React.ReactNode;
  className?: string;
};

type SortableContainerProps = ContainerProps & {
  render?: (args: ContainerProps) => JSX.Element;
};

const SortableContainer = ({
  render,
  children,
  className,
  ...rest
}: SortableContainerProps) => {
  if (render) {
    return render({ children, className, ...rest });
  }

  return (
    <ul className={classNames("sortable-container", className)}>{children}</ul>
  );
};

type SortableItemProps = {
  className?: string;
  id: string | number;
  children: React.ReactNode;
  render?: (args: ContainerProps) => JSX.Element;
  dragHandleStyle?: Record<string, string>;
  useDragHandle?: boolean;
  onSortEnd?: OnSortEndHandler;
  isDraggable: boolean;
};

const SortableItem = ({
  className,
  id,
  children,
  render,
  dragHandleStyle,
  useDragHandle,
  onSortEnd,
  isDraggable,
  ...rest
}: SortableItemProps) => {
  const {
    listeners,
    setNodeRef,
    transform,
    transition,
    items,
    index,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({
    id,
    disabled: !isDraggable,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (render) {
    return render({ children, ref: setNodeRef, style, ...listeners, ...rest });
  }

  const itemPropsForNoDragHandle = !useDragHandle ? listeners : {};

  return (
    <li
      ref={setNodeRef}
      className={classNames("sortable-element", className, {
        ["sortable-element--is-dragging"]: isDragging,
      })}
      {...itemPropsForNoDragHandle}
      style={style}
    >
      {useDragHandle && (
        <DragHandleWithArrows
          itemIndex={index}
          ref={setActivatorNodeRef}
          items={items}
          onSortEnd={onSortEnd}
          dragHandleStyle={dragHandleStyle}
          {...listeners}
        />
      )}
      {children}
    </li>
  );
};

type DragHandleWithArrowBaseProps = {
  dragHandleStyle: Record<string, string>;
  itemIndex: number;
  items: UniqueIdentifier[];
  onSortEnd: OnSortEndHandler;
  innerRef: any;
};

type DragHandleWithArrowBaseState = {
  visible: boolean;
};

class DragHandleWithArrowsBase extends React.Component<
  DragHandleWithArrowBaseProps,
  DragHandleWithArrowBaseState
> {
  state = {
    visible: false,
  };

  buttonUpRef: RefObject<HTMLButtonElement>;

  buttonDownRef: RefObject<HTMLButtonElement>;

  constructor(props: DragHandleWithArrowBaseProps) {
    super(props);
    this.buttonUpRef = React.createRef();
    this.buttonDownRef = React.createRef();
  }

  showDragHandle = () => this.setState({ visible: true });

  hideDragHandle = () => this.setState({ visible: false });

  render() {
    const { dragHandleStyle, itemIndex, items, onSortEnd, ...rest } =
      this.props;

    return (
      <div
        className="drag-handle"
        style={
          this.state.visible
            ? dragHandleStyle
            : { left: -100000, top: dragHandleStyle?.top || 0 }
        }
      >
        <div className="drag-handle__arrow">
          {itemIndex > 0 && (
            <button
              ref={this.buttonUpRef}
              type="button"
              aria-label="move item up"
              className="btn-reset width-100 height-100"
              onFocus={this.showDragHandle}
              onBlur={this.hideDragHandle}
              onClick={(ev) => {
                ev.preventDefault();
                onSortEnd({
                  oldIndex: itemIndex,
                  newIndex: itemIndex - 1,
                });

                if (itemIndex === 1) {
                  const downButton = this.buttonDownRef?.current;
                  if (downButton) {
                    downButton.focus();
                  }
                }
              }}
            >
              <KeyboardArrowUpIcon style={{ fontSize: 20 }} />
            </button>
          )}
        </div>
        <DragIcon
          style={{ fontSize: 20 }}
          ref={this.props.innerRef}
          {...rest}
        />
        <div className="drag-handle__arrow">
          {itemIndex + 1 < items.length && (
            <button
              ref={this.buttonDownRef}
              aria-label="move item down"
              type="button"
              className="btn-reset width-100 height-100"
              onFocus={this.showDragHandle}
              onBlur={this.hideDragHandle}
              onClick={(ev) => {
                ev.preventDefault();
                onSortEnd({
                  oldIndex: itemIndex,
                  newIndex: itemIndex + 1,
                });

                if (itemIndex === items.length - 2) {
                  const upButton = this.buttonUpRef?.current;
                  if (upButton) {
                    upButton.focus();
                  }
                }
              }}
            >
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </button>
          )}
        </div>
      </div>
    );
  }
}

const DragHandleWithArrows: any = React.forwardRef(
  (props: Omit<DragHandleWithArrowBaseProps, "innerRef">, ref) => {
    return <DragHandleWithArrowsBase innerRef={ref} {...props} />;
  },
);

type FieldArray = {
  _isFieldArray: boolean;
} & Pick<FieldArrayFieldsProps<any>, "get" | "getAll">;

const isFieldArray = (items: any): items is FieldArray => {
  return _.isObject(items) && "_isFieldArray" in items;
};

type OnSortEndHandler = (args: { oldIndex: number; newIndex: number }) => void;
