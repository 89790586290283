import type { WrappedFieldProps } from "redux-form";
import { Fields } from "redux-form";
import cs from "classnames";

import { Box, FormHelperText } from "@hexocean/braintrust-ui-components";
import { actionTypes } from "@js/apps/give-and-get-help/action-types";
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH_TO_DISPLAY_COUNTER,
  HASHTAGS_MAX_AMOUNT,
} from "@js/apps/give-and-get-help/constants";
import { LinksFromTextAdapterWrapper } from "@js/apps/give-and-get-help/forms/fields/links-from-text-adapter";
import {
  POST_FIELD_HASHTAGS,
  POST_FIELD_TEXT,
} from "@js/apps/give-and-get-help/forms/fields/post-description-field/constants";
import { PostLexicalTextField } from "@js/apps/give-and-get-help/forms/fields/post-lexical-textfield";
import { useOnUserStartedTyping } from "@js/apps/give-and-get-help/hooks/on-user-started-typing";
import { getError, shouldDisplayError } from "@js/forms/utils";
import { maxNumberOfValues } from "@js/forms/validators";
import { pluralize } from "@js/utils";

import { CharacterIndicator } from "../character-indicator";

type PostDescriptionFieldProps = {
  location: string;
  shouldAttachmentFieldsRender?: boolean;
};

export const PostDescriptionField = ({
  location,
  shouldAttachmentFieldsRender,
}: PostDescriptionFieldProps) => {
  return (
    <>
      <Fields
        names={[POST_FIELD_TEXT, POST_FIELD_HASHTAGS]}
        component={PostDescription}
        validate={{
          [POST_FIELD_HASHTAGS]: hashtagsValidation,
        }}
        location={location}
        shouldAttachmentFieldsRender={shouldAttachmentFieldsRender}
      />
      <LinksFromTextAdapterWrapper />
    </>
  );
};

const hashtagsValidation = maxNumberOfValues(
  HASHTAGS_MAX_AMOUNT,
  `You can add up to ${HASHTAGS_MAX_AMOUNT} hashtag${pluralize(
    HASHTAGS_MAX_AMOUNT,
  )}`,
);

type PostDescriptionProps = {
  location: string;
  shouldAttachmentFieldsRender?: boolean;
  [POST_FIELD_TEXT]: WrappedFieldProps;
  [POST_FIELD_HASHTAGS]: WrappedFieldProps;
};

const PostDescription = ({
  [POST_FIELD_TEXT]: textField,
  [POST_FIELD_HASHTAGS]: hashtagsField,
  location,
  shouldAttachmentFieldsRender,
}: PostDescriptionProps) => {
  useOnUserStartedTyping({
    input: textField.input,
    meta: textField.meta,
    action: {
      type: actionTypes["create/user-started-typing-description"],
      payload: {
        composer_location: location,
      },
    },
  });

  const onChange = (textContent: string) => {
    textField.input.onChange(textContent);
  };

  return (
    <>
      <Box
        className={cs(
          "editor-inside-wrapper editor-inside-wrapper--with-max-height",
          {
            "editor-inside-wrapper--no-attachments":
              !shouldAttachmentFieldsRender,
          },
        )}
      >
        <PostLexicalTextField
          onChange={onChange}
          placeholderElement={"Write your post..."}
          inputClassName="editor-input--post"
          text={textField}
          post_hashtags={hashtagsField}
        />
      </Box>
      <CharacterIndicator
        input={textField.input}
        maxLength={DESCRIPTION_MAX_LENGTH}
        minimumLengthToDisplayCounter={
          DESCRIPTION_MAX_LENGTH_TO_DISPLAY_COUNTER
        }
      />

      {shouldDisplayError([textField, hashtagsField]) && (
        <Box mt="5px" mb={0.5}>
          <FormHelperText error>
            {getError([textField, hashtagsField])}
          </FormHelperText>
        </Box>
      )}
    </>
  );
};
