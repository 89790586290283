import React from "react";

import { LightBox } from "@hexocean/braintrust-ui-components";

import { FileCommonBox } from "./file-common-box";

type ImagePreviewProps = {
  title: string;
  src: string;
  thumbnail?: string | null;
  showThumbnail?: boolean;
  size?: number;
};

export const ImagePreview = ({
  title,
  src,
  thumbnail,
  showThumbnail,
  size,
}: ImagePreviewProps) => {
  return (
    <LightBox
      content={<LightBoxContent title={title} src={src} />}
      preview={
        <a
          onClick={(ev) => {
            if (!ev.metaKey && !ev.ctrlKey) {
              return ev.preventDefault();
            }

            ev.stopPropagation();
          }}
          className="post-attachment post-attachment--image"
          href={src}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FileCommonBox
            color="var(--grey-1)"
            name={title}
            size={size}
            previewThumbnail={
              <LightBoxContent
                title={title}
                src={showThumbnail && thumbnail ? thumbnail : src}
              />
            }
          />
        </a>
      }
    />
  );
};

type LightBoxContentProps = {
  title: string;
  src: string;
};

const LightBoxContent = ({ src, title }: LightBoxContentProps) => {
  return (
    <div className="post-attachment__container">
      <img
        alt="Post attachment"
        src={src}
        title={title}
        width="64"
        height="64"
      />
    </div>
  );
};
