import type { TypedWrappedFieldProps } from "redux-form";

import type { Skill } from "@js/types/admin";

import { MAX_TOP_SKILLS_COUNT } from "../const";
import { SelectedSkillsList } from "../selected-skills-list";

type JobCreateSelectedSkillsListProps = TypedWrappedFieldProps<number[]> & {
  error?: string;
  topSkillsInput: TypedWrappedFieldProps<number[]>["input"];
  skills?: Skill[];
};
export const CreateJobSelectedSkillsList = ({
  input,
  error,
  topSkillsInput,
}: JobCreateSelectedSkillsListProps) => {
  return (
    <SelectedSkillsList
      error={error}
      skillsInput={input}
      favoriteSkillsInput={topSkillsInput}
      maxFavoriteSkillsCount={MAX_TOP_SKILLS_COUNT.create_job}
    />
  );
};
