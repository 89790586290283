import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";
import type { AxiosError, AxiosRequestConfig, Method } from "axios";
import axios from "axios";

type QueryArg = {
  url: string;
  method: Method;
  data?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
  validateStatus?: AxiosRequestConfig["validateStatus"];
};

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" },
  ): BaseQueryFn<QueryArg, unknown, unknown> =>
  async ({ url, method, data, params, ...rest }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        ...rest,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const API = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: "/api" }),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: 60,
  invalidationBehavior: "immediately", // switch back to delayed when https://github.com/reduxjs/redux-toolkit/issues/4749 is fixed
  tagTypes: [
    "InternalReviews",
    "TalentInviteSuggestions",
    "MessengerRooms",
    "RoomMessages",
    "Posts",
    "HelpOffers",
    "RelevantPosts",
    "HelpServices",
    "HelpServicesDrafts",
    "TalentReviews",
    "TalentReviewsSummary",
    "EmployerReviews",
    "OpenReviewProcesses",
    "FreelancerRoles",
    "FreelancerBids",
    "WalletActivity",
    "SimilarJobs",
    "NewestJobs",
    "EmployerBids",
    "EmployerOffers",
    "FreelancerOffers",
    "WalletBalance",
    "Jobs",
    "RelevantJobs",
    "ManagedJobs",
    "BookmarkedPosts",
    "MyPosts",
    "FreelancerHoverStateData",
    "SavedJobs",
    "ManagedEmployers",
    "TokenGrantPlans",
    "FrontendStorage",
    "SpaceRules",
    "MySpaces",
    "SpaceMembers",
    "Spaces",
    "SuggestedSpaces",
    "EmployerOpenJobs",
    "RewardRules",
    "TaxForm",
    "MoneyFlowDashboard",
    "ReferralReport",
    "TalentsWithJobsReport",
    "PaymentMethods",
    "OfferReport",
    "OfferNotes",
    "Notes",
    "InvoiceReport",
    "FreelancerJobInvitations",
    "TalentHomeRelevantPosts",
    "EmployerATSIntegrations",
    "EmployerATSIntegrationStatus",
    "FreelancerWorkExperience",
    "JobSubscribers",
    "JobSubscriberInvitations",
    "FreelancerSkills",
    "JobBidsLocations",
    "JobDrafts",
    "Interviews",
    "EmployerTeamMembers",
    "EmployerJobsMinimal",
    "FreelancerProfileCompletion",
    "FreelancerExternalProfiles",
    "EmployerHasExceededDailyJobsLimit",
    "ATSSyncedJobs",
    "EmployerInvoicesIds",
    "EmployerInvoicesMinimal",
    "JobOwners",
    "CanWithdrawTokens",
    "BidNotes",
    "EmployerInvoiceStatistics",
    "EmployerInvoices",
    "EmployerInvoiceStatusCounts",
    "EmployerBatchInvoices",
    "EmployerMyTalentStats",
    "EmployerMyTalent",
    "FreelancerJobOffers",
    "FreelancerArticles",
    "FreelancerPublicProfile",
    "ActiveSessions",
    "TokenTransactionsList",
    "GetPurchaseList",
    "InvitationsToManageEmployer",
    "EmployerCurrentOfferDeposit",
    "EmployerPublicProfile",
  ],
});

export const resetGlobalAPIState = () => API.util.resetApiState();
