import { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import type { RoleFormType } from "@js/apps/common/components/filters/forms/role-form";
import { useSkillsFilter } from "@js/apps/jobs/apps/listing/hooks/skills-filter";

type UseSkillsFilterContentArg = {
  role: TypedWrappedFieldProps<RoleFormType["role"]>;
  skills: TypedWrappedFieldProps<number[]>;
  defaultRole?: RoleFormType["role"];
};

export const useSkillsFilterContent = ({
  role,
  skills,
  defaultRole,
}: UseSkillsFilterContentArg) => {
  const {
    toggleSkill,
    popularSkills,
    applySkillFilter,
    clearSkillFilter,
    selectedRole,
    onChangeSearchSkill,
    skillsValue,
    roles,
    isFetchingPopularSkills,
  } = useSkillsFilter({ currentRole: role, skills, defaultRole });

  const selectedRolesData = useMemo(() => {
    if (!selectedRole || !roles) {
      return;
    }

    return roles.filter((singleRole) => {
      return selectedRole.includes(singleRole.id);
    });
  }, [roles, selectedRole]);

  const filteredSkills = useMemo(() => {
    return skillsValue.length
      ? popularSkills?.filter((skill) => !skillsValue.includes(skill.id))
      : popularSkills;
  }, [skillsValue, popularSkills]);

  return {
    toggleSkill,
    applySkillFilter,
    clearSkillFilter,
    onChangeSearchSkill,
    isFetchingPopularSkills,
    selectedRolesData,
    filteredSkills,
    skillsValue,
  };
};
