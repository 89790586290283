import {
  Box,
  Button,
  Divider,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useUser } from "@js/apps/common/hooks";
import { useHelpServicesMessageUser } from "@js/apps/freelancer/hooks/use-help-services-message-user";
import {
  BudgetLabel,
  CategoryBudgetBadgeContainer,
} from "@js/apps/give-and-get-help/components";
import { CategoryLabel } from "@js/apps/give-and-get-help/components/category-and-budget-utils/category-label";
import { useGetTalentReviewsSummaryQuery } from "@js/apps/reviews/api";
import { ModalInstance } from "@js/components/modal";
import type { FreelancerPublic } from "@js/types/freelancer";
import type { IPost, PostCategory } from "@js/types/give-and-get-help";

import { ReadMoreGraphic } from "./read-more-graphic";
import { ReviewsStatsBar } from "./review-stats-bar";

type ReadMoreModalProps = {
  title: string;
  profile: FreelancerPublic;
  budget: IPost["budget"];
  category: PostCategory;
  description?: string;
};

const ReadMoreModalContent = ({
  title,
  profile,
  budget,
  category,
  description,
}: ReadMoreModalProps): JSX.Element => {
  const { data: reviewSummary } = useGetTalentReviewsSummaryQuery({
    id: profile.id,
    helpCategoryId: category.id,
  });
  const { navigateToMessenger } = useHelpServicesMessageUser({
    receiverUserId: profile.user.id,
    initialMessage: `${SETTINGS.MESSAGE_IN_CONTEXT_OF_HELP_SERVICE_PREFIX} ${category.name}.`,
  });
  const currentUser = useUser();
  const isSmall = useMediaQuery("sm");
  const isOwnProfile = currentUser && profile.id === currentUser.freelancer;

  const handleMessageClick = async () => {
    await navigateToMessenger();
    closeReadMoreModal();
  };

  return (
    <Box className="read-more-modal-content">
      <Typography
        component="h3"
        fontWeight={500}
        variant="paragraph"
        size={isSmall ? "medium" : "large"}
        mb={isSmall ? 3 : 4}
        maxWidth="calc(100% - 24px)"
      >
        {profile.user.first_name} helps with...
      </Typography>
      <Box>
        <ReadMoreGraphic
          helpCategory={category}
          profile={profile}
          currentUser={currentUser ? currentUser : profile.user}
        />
      </Box>

      <Box
        maxWidth="56ch"
        mt={isSmall ? 3 : 4}
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant="paragraph"
          size={isSmall ? "medium" : "large"}
          component="h2"
          fontWeight={400}
        >
          {title}
        </Typography>
        {reviewSummary && Number(reviewSummary?.reviews_count) > 0 && (
          <ReviewsStatsBar reviewsSummary={reviewSummary} />
        )}
        <Typography
          mt={4}
          mb={4}
          variant="paragraph"
          size="medium"
          component="p"
        >
          {description}
        </Typography>
        <CategoryBudgetBadgeContainer
          categoryColor={category.color}
          alignSelf="start"
          padding="8px 16px"
          borderRadius={2}
        >
          <CategoryLabel category={category} withoutName />
          <BudgetLabel budget={budget} size="small" />
        </CategoryBudgetBadgeContainer>
        {!isOwnProfile && (
          <>
            <Divider color="secondary" sx={{ my: 4 }} />
            <Button
              variant="positive-2"
              shape="squared"
              onClick={handleMessageClick}
              className="read-more-modal__footer-action"
            >
              {`Message ${profile.user.first_name}`}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

const closeReadMoreModal = () => {
  ModalInstance.close();
};

export const openReadMoreModal = (readMoreModalProps: ReadMoreModalProps) => {
  return ModalInstance.open({
    closeButtonProps: { variant: "tertiary", size: "x-small" },
    className: "read-more-modal max-width-600",
    closeButton: true,
    disableEscapeKeyDown: true,
    keepOnBackdropClick: true,
    padding: false,
    onClose: ModalInstance.close,
    children: <ReadMoreModalContent {...readMoreModalProps} />,
  });
};
